@import '../../scss/variables.scss';

.annotation-cloud {
  position: absolute;
  background: #ececec;
  width: 240px;
  padding: 5px;
  z-index: 4;
  @include radius(8px);
  @include animation(show-annotation .2s forwards);

  textarea, .text-area {
    height: 60px;
    color: #202020;
    font-family: var(--font-family-georgia);
    font-size: 13px;
    line-height: 1.2;
    resize: none;
    outline: none;
    padding: 3px;
    width: 100%;
    border: 0 none;
    @include radius(8px);
    background: white;

    a {
      color: $secondary-color-carmine;
    }
  }

  .buttons {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;

    button {
      flex: 1;
      font-family: var(--font-family-cantarell);
      font-size: 10px;
      text-transform: uppercase;
      background-color: #202020;
      color: #fff;
      margin: 0 2px;
      cursor: pointer;

      &:hover {
        color: #202020;
        border: 1px solid #202020;
        background-color: #fff;
      }
    }
  }

  &:after {
    content: ' ';
    top: -12px;
    left: 113px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ececec;
  }

  .link-add-wrapper {
    display: none;
    margin: 4px 0;

    .url-text-area {
      height: 34px;
      padding: 2px;
      color: #202020;
      background: white;
      font-family: var(--font-family-georgia);
      font-size: 13px;
      line-height: 1.2;
      resize: none;
      outline: none;
      flex: 1 auto;
      margin-right: 4px;
      border: 0 none;
      z-index: 1;
      position: relative;
      @include radius(8px);
    }

    .btn {
      margin: 0;
      font-family: var(--font-family-cantarell);
      font-size: 10px;
      text-transform: uppercase;
      color: #202020;
      border: 1px solid #202020;
      background-color: #fff;

      &:hover {
        background-color: #202020;
        color: #fff;
      }
    }

    &.show {
      display: flex;
    }
  }
}

.annotation-tooltip {
  @include animation(show-annotation .1s forwards);
  position: absolute;
  background: #ececec;
  color: #202020;
  padding: 5px;
  font-size: 12px;
  width: 240px;
  @include radius(5px);
  z-index: 2;

  .edit-text, .text-area {
    display: none;
    height: 60px;
    padding: 3px;
    line-height: 1.2;
    resize: none;
    outline: none;
    width: 100%;
    margin-bottom: 4px;
    border: 0 none;
    z-index: 1;
    position: relative;
    @include radius(8px);
    color: #202020;
    background: white;
    font-family: var(--font-family-georgia);
    font-size: 13px;

    a {
      color: $secondary-color-carmine;
    }
  }

  &:after {
    content: ' ';
    top: -10px;
    left: 100px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ececec;
  }

  &:before {
    content: ' ';
    top: -32px;
    left: 76px;
    width: 70px;
    height: 70px;
    position: absolute;
    @include transform(rotate(45deg));
  }

  .text {
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;
    z-index: 1;
    font-size: 14px;
    position: relative;

    > * {
      color: #202020 !important;
    }

    a {
      color: #202020;
      text-decoration: underline;
    }
  }

  .buttons {
    display: flex;
    margin: 2px 0 0;
    position: relative;

    .btn {
      flex: 1;
      font-family: var(--font-family-cantarell);
      font-size: 10px;
      text-transform: uppercase;
      background-color: #202020;
      color: #fff;
      margin: 0 2px;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        color: #202020;
        border: 1px solid #202020;
      }

      &.save, &.cancel {
        display: none;
      }
    }
  }

  &.edit-mode {
    .text {
      display: none;
    }
    .edit-text {
      display: block;
    }
    .buttons {
      .save, .cancel {
        display: inline-block;
      }
      .edit, .close-an {
        display: none;
      }
    }
  }

  &.left {
    &:after {
      left: 20px;
    }

    &:before {
      left: -5px;
    }
  }

  &.right {
    &:after {
      left: 180px;
    }

    &:before {
      left: 155px;
    }
  }

  .link-add-wrapper {
    display: none;
    justify-content: space-between;
    margin-bottom: 5px;

    .url-text-area {
      height: 30px;
      padding: 2px;
      font-family: var(--font-family-titillium);
      font-size: 14px;
      line-height: 1.2;
      resize: none;
      outline: none;
      flex: 1 auto;
      margin-right: 4px;
      border: 0 none;
      z-index: 1;
      position: relative;
      @include radius(8px);
      background: white;
      color: $fifth-color-grey-don;
    }

    .btn {
      margin: 0;
      padding: 0 2px;
    }

    &.show {
      display: flex;
    }
  }
}

@-webkit-keyframes show-annotation {
  0%  {
    opacity: 0;
    margin-top: 5px;
    @include transform(scale(0.9));
  }
  100% {
    opacity: 1;
    margin-top: 0;
    @include transform(scale(1));
  }
}
@-moz-keyframes show-annotation {
  0%  {
    opacity: 0;
    margin-top: 5px;
    @include transform(scale(0.9));
  }
  100% {
    opacity: 1;
    margin-top: 0;
    @include transform(scale(1));
  }
}
@-o-keyframes show-annotation {
  0%  {
    opacity: 0;
    margin-top: 5px;
    @include transform(scale(0.9));
  }
  100% {
    opacity: 1;
    margin-top: 0;
    @include transform(scale(1));
  }
}
@keyframes show-annotation {
  0%  {
    opacity: 0;
    margin-top: 5px;
    @include transform(scale(0.9));
  }
  100% {
    opacity: 1;
    margin-top: 0;
    @include transform(scale(1));
  }
}

.ann-point {
  display: inline-block;
  position: relative;
  color: #fff;
  bottom: 10px;
  margin: 0 5px;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  background: #202020;
  width: 20px;
  height: 20px;
  line-height: 1.4;
  font-weight: normal;
  cursor: pointer;
  @include radius(5px);

  .edit-text {
    display: none;
    height: 60px;
    font-family: var(--font-family-titillium);
    font-size: 14px;
    line-height: 1.2;
    resize: none;
    outline: none;
    width: 100%;
    margin-bottom: 4px;
    border: 0 none;
    @include radius(8px);
  }

  .ann-text {
    @include animation(show-annotation .1s forwards);
    position: absolute;
    top: 36px;
    left: -100px;
    background: $fifth-color-grey-don;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    width: 220px;
    @include radius(5px);

    &:after {
      content: ' ';
      top: -10px;
      left: 100px;
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $fifth-color-grey-don;
    }

    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      height: 16px;
      top: -16px;
    }

    .text {
      display: block;
      margin: 0;
      padding: 0;
      text-align: left;
      position: relative;
      z-index: 1;
    }

    .buttons {
      margin: 2px 0 0;
      text-align: left;
      display: flex;

      .btn {
        display: inline-block;
        margin: 0 2px 0 0;
        padding: 2px 3px;

        &.save, &.cancel {
          display: none;
        }
      }
    }

    &.edit-mode {
      .text {
        display: none;
      }
      textarea {
        display: block;
      }
      .buttons {
        .save, .cancel {
          display: inline-block;
        }
        .edit {
          display: none;
        }
      }
    }

    &.left {
      left: -20px;

      &:after {
        left: 20px;
      }
    }

    &.right {
      left: -180px;

      &:after {
        left: 180px;
      }
    }
  }
}
