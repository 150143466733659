@import '../../scss/variables.scss';

toggle-switch {
  .switcher {
    display: inline-block;
    background-color: #acacac;
    padding: 2px;
    width: 38px;
    line-height: 0;
    @include transition(all .2s ease-in-out);
    @include radius(12px);
    cursor: pointer;

    .circle {
      background-color: #fff;
      display: inline-block;
      @include radius(50%);
      height: 20px;
      width: 20px;
      font-size: 0;
      line-height: 0;
      @include transition(all .2s ease-in-out);
    }

    &.check {
      background-color: $fourth-color-keppel;

      .circle {
        margin-left: 14px;
      }
    }

    &.disabled {
      opacity: .45;
      background-color: #acacac;
    }
  }

  &.black-white .switcher {
    background-color: white;
    border-radius: 20px;
    border-color: black;
    border-style: solid;
    border-width: 1px;

    width: 40px;

    .circle {
      background-color: black!important;
    }

    &.check {
      background-color: white;

      .circle {
        margin-left: 14px;
      }
    }
  }
}
