@import 'scss/variables.scss';

html, body {
  height: 100%;
}

html {
  font-size: 18px;
  background: $background-gray;

  @include large-screen-width() {
    font-size: 20px;
  }

  &.device--desktop, &.device--mobile {
    .grid {
      margin: 55px auto 30px;
    }

    .container {
      margin-top: 80px;
    }
  }
}

body {
  background: $background-gray;
  font-display: swap;

  &.fixed {
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    -webkit-overflow-scrolling: touch;
  }
}

$left-column-size: 250px;

.column {
  &--left {
    flex: 0 $left-column-size - 30px;
    margin: 0 30px 0 0;
  }

  &--right {
    flex: 0 390px;
    margin: 56px 0 0 60px;
  }

  &--center {
    flex: 1;
  }
}

@include max-tablet-width() {
  html, body {
    height: 100%;
    min-height: 100%;
  }
}

.navbar-section .navbar-toggler i {
  color: white;
}


button[disabled] {
  background-color: rgb(167, 167, 167) !important;
  border: 1px solid rgb(167, 167, 167) !important;
}

form {
  button{
    margin-top: 12px;
    margin-bottom: 12px;
  }

  label{
    //margin-top: 10px;
    margin-top: 0px;
  }

  &.notd-format{
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 6px;
  }

  &.notd-noformat{
    background: transparent;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0px;
    box-shadow:  none;
  }
}

.notd-input-error {
    background-color: #dc4444;
    color: white;
    margin: 5px 0px;
    border-radius: 10px;
    word-break: break-word;
    padding: 4px 10px;
    font-size: 14px;
}

::placeholder { // Chrome, FF, Opera, Safari 10.1+
    color: rgb(150,150,150)!important;
    opacity: 1; // FF
}

:-ms-input-placeholder { // IE 10-11
    color: rgb(150,150,150)!important;
}

::-ms-input-placeholder { // MS Edge
    color: rgb(150,150,150)!important;
}

.pac-container {  //this is a fix for google autocomplete not showing up in modal
  z-index: 10000 !important;
}
.btnNotd {
  border-radius: 6px;
  font-family: var(--font-family-palatino);
  font-size: 13px;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
}
.btn.notd-secondary-btn {
  min-width: 107px;
  height: 32px;
  background-color: #557b83;
  color: #ffffff;
  @extend .btnNotd;
}

.btn.notd-primary-btn {
  background-color: #ad3a3a;
  color: #ffffff;
  @extend .btnNotd;

}

.btn.notd-save-btn {
  background-color: rgb(85, 181, 201);
  color: #ffffff;

  @extend .btnNotd;
}

.btn.notd-cancel-btn {
  background-color: rgb(173, 129, 58);
  color: #ffffff;
  @extend .btnNotd;

}
.img-container{
  img{
    max-width: 100%;
  }
}

.uploader-form{

  .file-upload-group{

    margin-bottom:0px;
  }

  .notd-manual-location{
    >div:first-child{
      //font-weight: bold;
    }
  }


  .notd-thumb{
    >img{

      width: 73px;
      margin-bottom: 10px;
    }
  }

  input, textarea, select, label.file-display{

    //border: 1px solid #5b5151!important;
    border-radius: 6px!important;
    background-color: white!important;
    color: black!important;

  }

  form{
    label, input{
      margin-top: 0;
      margin-bottom: 0;
    }

    label{
      font-size: 13px;
    }

  }
}
.section{
  font-weight: bold;
  margin-top: 20px;
}
select.form-control {
  padding-top: 0px;
  padding-bottom: 0px;
}
.notd-copyright{
  font-weight: bold;
  margin-top: 10px;
}

.notd-filter-element{
  margin-top: 10px;
}

.stream-header-link {
  &:hover {
    text-decoration: none;
  }
}

.button-wrapper {

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media only screen and (min-width: 768px) {
    button{
      margin: 0;
    }
  }
}

.notd-invitation{
  .message{
    padding: 10px;
    font-weight: bold;

    p{
      span{
        color: #ad3a3a;
      }
    }
  }
  .buton-wrapper{
    display: flex;
    justify-content: flex-end;
  }

  a{
    color: #557b83;
  }
}

.notd-table {
  overflow: auto;
  font-size:14px;

  .notd-table-header {
    background: rgb(91, 81, 81);
    color: white;
    margin: 0;
  }

  .notd-table-row{
    border-style: none none solid none;
    border-width: 1px;
    border-color: rgb(194, 194, 194);

    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0px;
    word-break: break-all;
  }

  @media only screen and (max-width: 840px){
    .notd-table-header, .notd-table-row {
      min-width: 720px;
    }
  }
}

button[disabled] {
   cursor: no-drop;
}


ngb-popover-window.popover{
  color: white;

  .popover-body{
    padding: 5px;
    font-size: 13px;
    font-weight: normal;
    background: #fff;
    border-radius: 5px;
  }
  &:after {
    display: none;
  }
}

ngb-pagination{
  margin-top: 5px;
  margin-bottom: 5px;

  &.boundary-links {

    > ul{

      a {
        &[aria-label="Next"], &[aria-label="Previous"], &[aria-label="First"], &[aria-label="Last"] {
          border-color: transparent!important;
          background-color: transparent!important;
          font-weight: bold;
        }
      }
    }
  }

  .page-link {
      color: #fff;
      background-color: #423230;
      border-color: transparent rgb(230,230,230);

      @media only screen and (max-width: 500px) {
        border-color: transparent;
      }
  }

  .page-item.active .page-link {
      color: #fff;
      background-color: #423230;
      border-color: transparent;
  }

  ul.pagination{
    flex-wrap: wrap;
    justify-content: space-around;
  }
  li, a {
    outline: 0;
  }

  .page-link {
      color: #423230;
      background-color: transparent;
      user-select: none;
      border-radius: 2px;
  }
  .disabled{
    opacity: 0.5;
  }

}

.fa-spin.big{
  margin-top: 5px;
  margin-bottom: 5px;

}

.modal-content .modal-cross {
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
  width: 32px;
  height: 32px;

  svg{
    fill: rgb(85, 123, 131);
    filter: none;
  }
}

.grid {
  width: $gridWidth;
  margin: 0 auto;

  /*
  @media only screen and (max-width: 991px) {
    width: auto;
  }
  */

  @include max-phone-width() {
    width: auto;
  }

}

.notd-text.content {
  color: $sixth-color-content;
  padding: 20px 40px 100px;

  @media only screen and (max-width: 991px) {
    padding: 20px 40px 30px;
  }

  .subpage-title {
    font-size: 38px;
    padding: 14px 0;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 10px;
  }

  .content-link {
    color: $fourth-color-keppel;
    font-weight: bold;
  }

  p {
    @include max-phone-width() {
      line-height: 20px;
    }
  }

  .side-nav {
    display: none;

    @include max-phone-width() {
      display: flex;
    }
  }
}

.navigation-bar {
  margin: 0 0 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      padding: 0 0 3px 0;

      a {
        color: $fourth-color-keppel;
        font-weight: normal;
        font-family: var(--font-family-georgia);
        text-transform: uppercase;
        font-size: 0.7em;
      }

      &:after {
        content: '>';
        display: inline-block;
        color: $fifth-color-grey-don;
        padding: 0 3px 0 5px;
      }

      &:last-child {
        a {
          color: $fifth-color-grey-don;
        }

        &:after {
          content: '';
        }
      }
    }
  }
}

.container {
  margin-top: 20px;
  display: flex;
  max-width: inherit;

  @include max-phone-width() {
    flex-direction: column;
    min-height: calc(100% - 160px);
  }

  .content-wrapper {
    flex: 1 1 auto;

    h3 {
      margin-bottom: 15px;

      @include max-phone-width() {
        margin-left: 30px;
      }
    }
  }

  @media only screen and (min-width: 576px) {
    width: 92%; // 5% margin left and right
  }

}

.modal-content {
  @include radius(15px);

  .modal-header {
    button {
      &.close {
        background: none !important;

        span {
          position: relative;
          top: -10px;
          right: 10px;
        }
      }
    }
  }
  .modal-footer {}
}

* {
  &:focus {
    @include box-shadow(none !important);
    outline: none !important;
  }
}

.pt {
  &-10 {
    padding-top: 10px;
  }

  &-20 {
    padding-top: 20px;
  }

  &-30 {
    padding-top: 30px;
  }
}

.pb {
  &-10 {
    padding-bottom: 10px;
  }

  &-20 {
    padding-bottom: 20px;
  }

  &-30 {
    padding-bottom: 30px;
  }
}

.mt {
  &-10 {
    margin-top: 10px;
  }

  &-20 {
    margin-top: 20px;
  }

  &-30 {
    margin-top: 30px;
  }
}

.info-message {
  display: flex;
  margin: 0 0 10px;
  padding: 2px 10px;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ced4da;
  background: rgba(206, 212, 218, 0.1);

  @include radius(5px);

  .ng-fa-icon {
    color: $third-color-blue;
    font-size: 24px;
  }

  p {
    margin: 0 6px;
    font-size: 14px;
  }

  &.large {
    p {
      font-size: 1.2em;
    }
  }

  &.alert {
    border: 1px solid #8c222229;
    background: #ad3a3a29;

    .ng-fa-icon {
      color: #dc3545;
    }
  }
}

.table-basic-style {
  padding: 0 0 20px;

  @include max-phone-width() {
    overflow: auto;
  }

  table {
    width: 100%;
    border: 1px solid #d6d6d6;
    background-color: #fff;
    margin-bottom: 10px;
    overflow: hidden;
    @include box-shadow(rgba(0, 0, 0, 0.05) 0 0 20px 0);
    @include radius(.25rem);

    @include max-phone-width() {
      width: 800px;
    }

    .link {
      color: $third-color-blue;
    }

    thead {
      th {
        background: #d6d6d67a;
        padding: 3px;
        text-align: center;
      }
    }

    tbody {
      tr {
        @include transition(all .2s ease-out);
        background: none;

        td {
          text-align: center;
          font-size: 0.8rem;
          padding: 3px 1px;
          border-bottom: 1px solid #d6d6d6ba;

          &.note-name {
            max-width: 180px;
          }

          &.location {
            max-width: 140px;
          }

          &.options {
            min-width: 80px;
          }
        }

        &:hover, &.selected {
          background: #f5f5f5d9;
        }

        .btn {
          padding: 2px 3px;
          margin: 1px 2px;
          font-size: 12px;

          &:hover {
            background: $third-color-blue;
          }
        }

        .tag {
          background: $third-color-blue;
          color: #fff;
          font-size: 11px;
          padding: 2px 5px;
          margin: 1px;
          @include radius(.25rem);
        }
      }
    }
  }

  .show-more {
    .btn {
      font-size: 16px;
      color: white;
      @include transition(all .4s ease-in);

      &:hover {
        background: $third-color-blue;
      }
    }
  }
}

.clear {
  clear: both;
}

.button-spinner {
  position: relative;
  padding-right: 35px;
  //background: lightgrey !important;
  pointer-events: none;
  @include transition(all .2s ease-in-out);

  &:after {
    content: '';
    position: absolute;
    right: 10px;
    border: 3px solid #fff;
    border-color: #fff #fff transparent transparent;
    width: 20px;
    height: 20px;
    @include animation(button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite);
    @include radius(50%);
  }
}

.audio-file-included {
  font-size: .7em;
  background: #ebebeb4f;
  border: 1px solid #d6d6d6;
  padding: 3px 7px 3px 5px;
  @include radius(5px);
  display: inline-block;

  svg {
    margin-right: 3px;
    color: $third-color-blue;
  }
}

.preview-modal {
  .modal-content {
    padding: 35px 25px 25px;
    position: relative;

    .preview-note-title {
      font-family: var(--font-family-palatino);
      color: black;
      font-size: 24px;
      margin: 7px 0 12px;
      font-weight: 700;
      word-break: break-word;
    }

    .content-paragraph {
      clear: both;
      font-style: italic;
      background-color: #fafafa9c !important;
      border-top: 1px solid #80808033;
      border-bottom: 1px solid #80808033;
      margin: 7px 0;
      padding: 5px;
    }

    blockquote {
      border-left: 6px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
      line-height: 1.4em;
      font-size: 0.9em;
    }

    .tags-section {
      margin-bottom: 10px;
  
      .tag {
        color: $main-background-color;
        font-size: 13px;
        background: $fourth-color-keppel;
        display: inline-block;
        padding: 1px 7px;
        margin-right: 5px;
        @include radius(5px);
        user-select: none;
      }
    }
    
    .premium-content {
      font-size: 1em;
      font-family: var(--font-family-palatino);
      line-height: 1.9em;
      flex: 1 0;
      word-break: break-word;

      img {
        min-width: 300px;
        max-width: 100%;

        + em {
          display: block;
        }
      }

      @include max-phone-width() {
        width: 100%;

        .caption-image-wrapper {
          display: block !important;
          align-items: unset !important;
          flex-direction: unset !important;
          flex-flow: unset !important;

          img, span {
            width: 100% !important;
          }

          span {
            padding: 2px 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
            border-top: 0 !important;
            border-bottom: 1px solid #0000002e !important;
          }

          &.left, &.right {
            width: 40% !important;
          }
        }

        img {
          min-width: initial !important;
        }
      }

      @include ipad-pro-width() {
        .caption-image-wrapper {
          min-width: 49%;

          img {
            min-width: initial;
          }
        }
      }

      ol {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        counter-reset: li-counter;

        li {
          padding: 0 0 7px;
          counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
          counter-increment: list-num;

          &:before {
            content: counter(list-num, decimal) '. ';
            counter-increment: li-counter;
          }

          &.ql-indent-1 {
            padding-left: 20px;
            counter-increment: list-1;
            counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

            &:before {
              content: counter(list-1, lower-alpha) '. ';
            }
          }

          &.ql-indent-2 {
            padding-left: 40px;
            counter-increment: list-2;
            counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;

            &:before {
              content: counter(list-2, lower-roman) '. ';
            }
          }

          &.ql-indent-3 {
            padding-left: 60px;
            counter-increment: list-3;
            counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;

            &:before {
              content: counter(list-3, decimal) '. ';
            }
          }

          &.ql-indent-4 {
            padding-left: 80px;
            counter-increment: list-4;
            counter-reset: list-5 list-6 list-7 list-8 list-9;

            &:before {
              content: counter(list-4, lower-alpha) '. ';
            }
          }

          &.ql-indent-5 {
            padding-left: 100px;
            counter-increment: list-5;
            counter-reset: list-6 list-7 list-8 list-9;

            &:before {
              content: counter(list-5, lower-roman) '. ';
            }
          }

          &.ql-indent-6 {
            padding-left: 120px;
            counter-increment: list-6;
            counter-reset: list-7 list-8 list-9;

            &:before {
              content: counter(list-6, decimal) '. ';
            }
          }

          &.ql-indent-7 {
            padding-left: 140px;
            counter-increment: list-7;
            counter-reset: list-8 list-9;

            &:before {
              content: counter(list-7, lower-alpha) '. ';
            }
          }

          &.ql-indent-8 {
            padding-left: 160px;

            .ql-editor ol li.ql-indent-8 {
              counter-increment: list-8;
            }
            .ql-editor ol li.ql-indent-8:before {
              content: counter(list-8, lower-roman) '. ';
            }
            .ql-editor ol li.ql-indent-8 {
              counter-reset: list-9;
            }
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 0 0 7px;

          &:before {
            content: '\2022';
            padding: 0 4px;
          }
        }
      }

      .ql-size-small {
        font-size: 0.75em;
      }
      .ql-size-large {
        font-size: 1.5em;
      }
      .ql-size-huge {
        font-size: 2.5em;
      }

      .ql-align-justify {
        text-align: justify;
      }
      .ql-align-center {
        text-align: center;
      }

      .ql-align-left {
        text-align: left;
      }

      .ql-align-right {
        text-align: right;
      }
    }

    .close-preview-modal-btn {
      display: inline-block;
      cursor: pointer;
      top: 4px;
      width: 30px;
      height: 30px;
      right: 5px;
      position: absolute;
      z-index: 1;

      svg {
        width: 30px;
        height: 30px;
        @include transition(all .3s ease-in-out);

        &:hover {
          opacity: .7;
          @include transform(rotate(180deg));
        }
      }
    }

    .close-button {
      background-color: $fourth-color-keppel;
      color: white;

      &:hover {
        background-color: $third-color-blue;
      }
    }
  }
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

@keyframes button-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


