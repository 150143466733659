@import '../../scss/variables.scss';

.bank-mode-switch {
  span, button, div {
    font-weight: bold;
    color: #557b83;
  }

  .link {
    cursor: pointer;

    &:hover {
      color: $secondary-color-carmine;
    }
  }
}

notd-banking-form {
  .bank-type{
    label {
      color: gray;
    }

    h6 {
      font-weight: bold;
    }
  }

  label{
    cursor: pointer;

    > span {
      padding-left: 20px;
      padding-right: 5px;
    }

    .ng-fa-icon {
      font-weight: bold;
      margin: 0 3px;
    }

    &.active {
      color: #ad3a3a;
    }
  }

  input[type="radio"] {
    display: none;
    cursor: pointer;
    outline: 0;

    &+label{
      cursor: pointer;
      margin-left: 5px;
      margin-right: 10px;
    }
  }

  label {
    position: relative;

    &::after{
      position: absolute;
      top: 4px;
      left: calc(100% + 4px);
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-size: 14px;
    }

    &.valid::after{
      content: "\F058";
      color: rgba(0, 128, 0, 1);
    }

    &.required::after{
      content: "\f069";
      color: rgba(173, 58, 58, 1);
      font-size: 10px;
    }

    .validation-icon {
      font-size: 18px;
      color: $fourth-color-keppel;

      &.required {
        color: $secondary-color-carmine;
      }
    }
  }

  input.ng-invalid.ng-dirty, input.ng-invalid.ng-dirty:focus{
    background: rgba(255, 0, 0, 0.10);
  }

  .change-account-link {
    margin-left: 20px;
    font-size: 12px;
    color: $secondary-color-carmine !important;
    cursor: pointer;

    &:hover {
      color: $third-color-blue !important;
    }
  }
}
