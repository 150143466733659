@import '../../scss/variables.scss';

$notd-navbar-height: 56px;

:host {
  .user-menu-wrapper {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .notd-account-btn {
    background: none;
    padding: 0;
    margin: 0 10px 0 10px;

    &:focus {
      @include box-shadow(none);
    }

    span {
      font-size: 14px;
    }

    fa-icon {
      font-size: 20px;
      position: relative;
      top: 2px;
    }

    &:after {
      display: none;
    }

    .rounded {
      width: 50px;
      @include radius(50% !important);
    }
  }

  .profile-submenu {
    li {
      button {
        padding: 10px;
      }
    }
  }

  .touch-device {
    &--hide {
      display: flex;

      @include max-w-1024() {
        display: none;
      }
    }
    &--show {
      display: none;

      @include max-w-1024() {
        display: flex;
      }
    }
  }

  share-button {
    display: none;
  }

  .share-img-content {
    display: none;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../../assets/why-publish/bg-intro.png');
    background-repeat: no-repeat;
    background-position: center;
    @include background-size(cover);
    width: 360px;
    min-height: 600px;
    padding: 15px;
    position: relative;

    @include radius(15px);

    h1, h2 {
      z-index: 1;
      color: #fff;
      font-family: var(--font-family-titillium);
      font-weight: normal;
      text-align: center;
    }

    h1 {
      font-size: 1.6em;
      line-height: 1.3em;
      margin: 0 0 40px;
    }

    h2 {
      font-size: 1.3em;
      font-weight: 200;
    }

    &__logo {
      width: 90px;
      margin: 0 auto 50px;
    }
  }

}

authenticated-user-navbar-element {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }

  notification-button {
    .dropdown-menu{
      left: -180px!important;
    }
  }

  .notification, .profile{
    min-height: $notd-navbar-height;
    >div {
      >button {
        height: $notd-navbar-height;
        >span {
          top: 6px!important;
        }
      }
    }

    @media (min-width: 992px) {
      >div {
        position: relative;
        height: $notd-navbar-height;
      }
    }
  }

  .create-stream {
    margin-left: 10px;

    @media only screen and (max-width: 991px) {
      padding-top: 10px;
      margin-left: 0px;
    }

    a, button {
      font-weight: bold;
      max-width: 200px;
    }
  }

  span {
    white-space: normal;
  }

  button {
    color: white;

    &.notd-account-btn {
      .avatar {
        display: inline-flex;
        margin: 2px 0 0;
        width: 50px;
        height: 50px;
        overflow: hidden;
        @include radius(50%);
      }

      i, span{
        font-weight: 700;
      }
      span {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .profile {
    > div > button > img, > div > button > svg {
      display: inline-flex;
      max-height: 50px;
      max-width: 50px;
      width: auto;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
      overflow: hidden;
    }

    @media (min-width: 992px) {
      position: relative;
    }

    .dropdown-toggle {
      fa-icon {
        color: red;
      }
    }

    .dropdown-menu {
      margin-left: 0px!important;

      @media (min-width: 992px) {
        left: auto;
        width: 220px;
        margin: auto;
        right: 0;
        position: absolute;
      }

      @media only screen and (max-width: 767px) {
        width: auto!important;
      }
    }
  }

  .notd-dropdown-menu {
    padding:0;
    min-width: 100%;
    background-color: #efefef;
    border: 0 none;

    .disabled {
      span, fa-icon {
        color: rgb(227, 0, 0);
      }
    }

    > li {
      font-family: var(--font-family-georgia);
      font-size: 14px;
      border-bottom: 0 none;
      padding: 5px;

      fa-icon {
        display: inline-block;
        width: 30px;
        font-size: 20px;
        text-align: center;
        margin-right: 5px;
      }

      > button {
        font-family: var(--font-family-cantarell);
        font-size: .9em;
        text-transform: uppercase;
        position: relative;
        padding-top:10px;
        padding-bottom:10px;
        text-align: left!important;
        z-index: 100;
        background: transparent;

        &:hover {
          background-color: white;
        }

        &.custom-svg-icon {
          display: flex;
          align-items: center;
          
          svg {
            width: 16px;
            font-size: 20px;
            text-align: center;
            margin: 6px 12px 6px 6px;
          }
        }
      }

      .menu--link {
        display: flex;
        justify-content: flex-start;
        padding: 10px 0 10px 10px;
        margin: 0;
        align-items: center;
        font-family: var(--font-family-cantarell);
        font-size: .9em;
        text-transform: uppercase;
        color: #212529;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          background-color: #fff;
        }
      }

      ul {
        list-style: none;
      }

      >ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        li {
          cursor: pointer;
          display: flex;
          align-items: stretch;

          &.is-active {
            a {
              color: #ad3a3a;
              font-weight: bold;
            }
          }

          &:hover {
            background-color: #f9f9f9;
          }

          a {
            width: 100%;
            text-decoration: none;
            padding: 5px!important;
            color: #292b2c;
            display: block;
            text-align: center!important;
            font-family: var(--font-family-cantarell);
            font-size: .8em;
            text-transform: uppercase;

            .avatar-img {
              position: relative;
              height: 40px;
              width: 40px;
              @include radius(50%);
              overflow: hidden;
              margin: 0 auto;

              img {
                height: 40px;
                width: auto !important;
                position: absolute;
                top: 0;
                left: 50%;

                @include transform(translate(-50%, 0));
                @include radius(0px !important);
              }
            }

            > img {
              width: 40px !important;
              padding-right: 4px;
              margin: auto;
            }

            > div, > span {
              line-height: 16px;
              padding: 5px;
              word-break: break-word;
            }
          }
        }
      }

      @media only screen and (max-width: 991px) {
        > ul {
          > li {
            flex-basis: 50%;
            flex-grow: 1;
          }
        }
      }

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        > ul {
          > li {
            flex-basis: 33%;
            flex-grow: 1;
          }
        }
      }

      @media only screen and (min-width: 992px) {
        position: static;

        > ul {
          max-height: 7*43px;
          overflow: auto;
          background-color: transparent;
          opacity: 0;
          flex-direction: row;
          visibility: hidden;
          justify-content: flex-right;
          position: absolute;
          top:0;
          right: 0%;
          padding: 0px;
          min-width: 400px;

          li {
            flex-basis: 33%;
            flex-grow: 0;
            background-color: white;
            border: 0 none;
          }
        }

        &:hover {
          > ul {
            opacity: 1;
            right: 100%;
            visibility: visible;
          }
        }
      }
    }

    > li:first-child {
      border-style: none;
    }
  }

  .stream-list{
    li{
      position: relative;
    }
  }

  .community-stream{
    color: rgb(155, 155, 155);
    position: absolute;
    right: 2px;
    top: 2px;
    font-size: 15px!important;
  }

}
