@import '../../scss/variables.scss';

notification-button {
  position: relative;

  .dropdown-menu {
    margin-top: 2px !important;
  }
  .show > .dropdown-menu {
    display: block;
  }

  .notifications-dropdown-list {
    font-family: var(--font-family-georgia);
    position: absolute;
    // @include box-shadow(1px 2px 10px rgba(0, 0, 0, 0.3));
    padding: 0;
    top: 46px !important;
    left: auto !important;
    right: -70px !important;
    width: 380px;
    background-color: #efefef;
    border: 0 none;
    @include radius(5px);
    @include transform(none !important);

    @include max-tablet-width() {
      position: fixed !important;
      top: 50px !important;
      right: 20px !important;
      left: 20px !important;
      width: auto;
    }

    @include large-screen-width() {
      width: 700px;
    }

    &:before {
      display: none;
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #f6f6f6;
      top: -8px;
      left: 50%;
      @include transform(translate(-50%, 0));

      @include max-tablet-width() {
        left: auto;
        right: 55px;
        @include transform(none);
      }

      @include max-tablet-width() {
        right: 54px;
      }

      @include large-screen-width() {
        left: auto;
        right: 181px;
        @include transform(none);
      }
    }

    .header {
      padding: 12px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d6d6d6;
      outline: 0 !important;
      text-align: left;
      list-style: none;
      background-color: #efefef;
      -webkit-border-top-left-radius: 10px;
      -webkit-border-top-right-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-topright: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .fa {
        font-size: 14px;
      }

      .title-name {
        text-transform: uppercase;
        font-family: var(--font-family-cantarell);
        color: $primary-color-black;
        font-size: .9em;
        font-weight: bold;
      }

      .links {
        display: flex;
        font-size: .8em;

        a {
          color: $third-color-blue;
          text-decoration: none;
          margin-left: 8px;
          cursor: pointer;

          &:hover {
            color: $secondary-color-carmine;
          }
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: auto;
      max-height: 470px;
      scroll-behavior: smooth;

      @include max-tablet-width() {
        max-height: 70vh;
      }

      @include large-screen-width() {
        max-height: 70vh;
      }

      li {
        border-bottom: 1px solid #d6d6d647;
        outline: 0 !important;

        .loader {
          flex: 0 0 45px;
          text-indent: -10px;
        }

        a {
          padding: 20px;
          font-size: .85em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          color: $third-color-blue;
          @include transition(all .1s linear);

          @include max-phone-width() {
            font-size: 1.15em;
          }

          .thumbnail {
            width: 46px;
            margin-right: 12px;
            outline: 0 !important;
            display: none;

            @include large-screen-width() {
              width: 66px;
            }
          }

          .n-name {
            font-size: .8em;
            text-align: left;
            flex: 1 1 auto;
            line-height: 1.2;
            color: #000;
            white-space: normal;
            outline: 0 !important;
            text-decoration: none;

            @include large-screen-width() {
              font-size: 1.1em;
            }
          }

          &.image-loaded {
            .thumbnail {
              display: block;
            }

            .loader {
              display: none;
            }
          }

          &:hover {
            background-color: #f9f9f9;
          }
        }
      }
    }

    .bottom {
      color: #000;
      display: block;
      text-align: center;
      font-size: 14px;
      padding: 8px 0;
      border-top: 1px solid #d6d6d6;
    }

    .arrow-up {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid black;
    }

    .notifications__empty {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;

      @include radius(20px);

      fa-icon {
        font-size: 4em;
        margin: 10px auto;
        line-height: 1em;
        color: #bababa;
      }

      // &:before {
      //   position: absolute;
      //   content: "";
      //   width: 0;
      //   top: -8px;
      //   right: 89px;
      //   height: 0;
      //   border-left: 15px solid transparent;
      //   border-right: 15px solid transparent;
      //   border-bottom: 15px solid #f0eeef;
      // }
    }
  }

  .dropdown-toggle svg {
    color: #3aada8!important;
    font-size: 18px;
  }

  .notifications-total {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
    background: #c32c02;
    left: 30px;
    top: -2px;
    font-size: 12px;

    &.big {
      font-size: 11px;
      width: 22px;
      height: 22px;
    }
  }

  .dropdown-toggle::after{
    display: none;
  }

  .notifications-button {
    background-color: transparent;
    position: relative;
    width: 60px;
    outline: 0;
    border-style: none;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      background: none;

      fa-icon {
        svg {
          path {
            fill: #3FCFCA;
            @include transition(all .3s ease-in-out);
          }
        }
      }
    }
  }

}


