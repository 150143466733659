@import '../scss/variables.scss';

:host {
  display: flex;
  height: 100%;
  flex-direction: column;

  --font-1: Georgia, Palatino, "Times New Roman", Times, serif;
  --color-blue-1: #39aea9;

  .container {
    flex: 1 0 auto;
  }

  .content-wrapper {
    @include max-tablet-width() {
      overflow: hidden;
    }
  }

  .display {
    &--flex {
      display: flex;

      .split--50p {
        flex: 0 50%;
      }

      .m {
        &--r {
          &--20 {
            margin: 0 20px 0 0;
          }
        }
      }
    }
  }

  .user-profile-page {
    @include max-w-1024() {
      flex-direction: column;
    }

    &__content {
      font-family: var(--font-1);
      display: flex;
      flex: 1;
      flex-direction: column;

      &__profile {
        display: flex;

        @include max-w-1024() {
          flex-direction: column;
        }

        &__avatar {
          flex: 0 230px;
          margin: 0 30px 0 20px;
          
          @include max-w-1024() {
            flex: 1;
            margin: 30px;
            justify-content: center;
            align-items: center;
          }
        }

        &__form {
          flex: 1;

          &__section {
            &__headline {
              font-size: 1.25em;
              margin: 20px 0 10px;
            }
          }

          &__input-field {
            display: flex;
            flex-direction: column;
            margin: 0 0 20px;

            &__label {
              margin: 0 0 10px;
              font-size: 0.95em;
              font-family: var(--font-1);
            }

            &__field {
              font-family: var(--font-1);
              font-size: 1em;
              padding: 12px;
              border: 1px solid #80808029;
              @include radius(10px);

              @include max-phone-width() {
                width: 100%;
              }

              &.textarea {
                resize: none;
                height: 100px;
              }
            }
          }

          &__button {
            margin: 25px 0;
            display: flex;
            justify-content: right;

            @include max-w-1024() {
              justify-content: center;
            }

            button {
              font-size: 0.9em;

              &:disabled {
                border: 0 none;
              }
            }
          }

          &__errors {
            display: flex;
            flex-direction: column;
    
            &__error {
              font-size: 0.85em;
              margin: 10px 0;
              display: flex;
              align-items: center;
    
              svg {
                width: 22px;
                height: 22px;
                margin: 0 8px 0 0;
              }
            }
          }
        }
      }

      &__h2 {
        font-size: 1.25em;
      }

      &__activity {
        margin: 30px 0;
        padding: 30px 0 0;
        border-top: 1px solid #8080802b;
      }

      &__subsriptions {
        margin: 35px 0;
      }
    }

    .user-email {
      &__address {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          margin: 0 10px 0 0;
        }
      }

      &__info {
        display: flex;
        margin: 7px 0;
        align-items: center;
        font-size: .9em;
        color: var(--color-blue-1);

        svg {
          width: 20px;
          margin: 0 10px 0 0;
        }
      }
    }

    .user-notifications {
      margin: 20px 0;

      &__checkbox {
        display: flex;
        align-items: center;
        font-size: 1em;
        cursor: pointer;
        margin: 12px 0;

        svg {
          margin: 0 10px 0 0;
          path {
            fill: var(--color-blue-1);
          }
        }
      }
    }

    .user-location {
      margin: 20px 0;

      notd-address {
        margin: 15px 0;
        display: block;
      }

      &__distance {
        display: flex;
        align-items: center;
        margin: 15px 0;

        &__label {
          margin: 0 15px 0 0;
        }

        toggle-switch {
          margin: 0 10px;
        }

        &--selected {
          color: var(--color-blue-1);
        }
      }
    }

    .user-delete {
      &__link {
        color: var(--color-blue-1);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      &__info {
        display: flex;
        align-items: center;
        margin: 20px 0;

        svg {
          margin: 0 8px 0 0;
          width: 20px;
          height: 20px;
        }

        font-size: .8em;
        color: grey;
      }
    }
  }
}


app-user {
  display: flex;
  height: 100%;
  flex-direction: column;

  .container {
    flex: 1 0 auto;
  }

  .save-section{
    border-style: solid none none;
    border-width: 1px;
    border-color: rgba(91, 81, 81, 0.078);
  }

  ::ng-deep notd-address {
    .buttons-container {
      .locator-button {
        padding: .5rem 2px;
      }
    }
  }

  .popover-header{
    display: none;
  }

  input[type="checkbox"] {
    display: none;
  }

  .email-section{
    margin-top: 20px;
    display: flex;
    flex-flow: wrap;
  }

  .error {
    border: 1px solid $secondary-color-carmine;
  }

  .form-error {
    display: none;
    padding: 3px 0 2px;
    font-size: 13px;
    color: $secondary-color-carmine;

    &.show {
      display: block !important;
    }
  }

  .label-check{
    cursor: pointer;

    .ng-fa-icon {
      color: $third-color-blue;
      width: 24px;
      margin-right: 5px;
    }
  }

  .distance-measure{
    .active{
      color: #d12d2d;
    }
    span{
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  label:not(.label-check){
    font-size: 14px;
    margin: 0;
    position: relative;
  }

  .user-data-wrapper {
    display: flex;

    .img-container {
      flex: 0 1 200px;
      margin: 0 15px;

      @include max-tablet-width() {
        .profile-avatar {
          margin: 0 auto;
        }
      }

      @include max-tablet-width() {
        flex: 1 1 auto;
        margin: 0 15px;
      }
    }

    .user-details {
      flex: 1 1;
      margin-right: 15px;

      @include max-tablet-width() {
        flex: 1 1 auto;
        margin: 0 15px;
      }
    }
  }

  .user-image-avatar {
    height: 202px;
    width: 202px;
    @include radius(50%);
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    @include max-w-1024() {
      height: 170px;
      width: 170px;
    }

    .default-image {
      height: 100%;
      width: auto;
      position: absolute;
      top: 0;
      left: 50%;
      min-width: 100%;
      object-fit: cover;
      @include transform(translate(-50%, 0));
      @include radius(0px);
    }

    &-default {
      .show-editor-modal {
        color: $sixth-color-content !important;
      }
    }
  }

  .error-message {
    display: block;
    text-align: center;
    color: $secondary-color-carmine;
    font-size: 14px;
    font-weight: bold;
    margin: 4px 0;
  }

  .subscription-payment-wrapper {
    display: none;
    border: 1px solid rgba(0,0,0,.125);
    height: 0;
    max-height: 2000px;
    margin: 0 0 15px;
    overflow: hidden;
    @include box-shadow(rgba(0, 0, 0, 0.05) 0 0 20px 0);
    @include radius(5px);
    @include transition(all .25s ease-in-out);

    .payment-content-wrapper {
      display: none;
      margin: 0 auto;
      border: 0 none;
      padding: 0 50px;
      @include transition(opacity .2s ease-in-out .2s);
      font-size: .85em;
      font-weight: bold;

      .message {
        padding: 0 0 0 10px;
      }

      fa-icon {
        color: $fourth-color-keppel;
      }

      &.error {
        display: flex;
        color: #f24f5d;

        .message {
          color: #000;
          padding: 0 0 0 6px;

          .reload-page {
            color: $fourth-color-keppel;
            text-decoration: underline;
            font-weight: .8em;
            cursor: pointer;

            &:hover {
              color: #000;
            }
          }
        }
      }
    }

    iframe {
      width: 100%;
      height: 100%;
      border: 0 none;
      display: none;

      .pub-brand {
        display: none;
      }
    }

    &.show {
      display: flex;
      height: 90px !important;
      justify-content: center;
      align-items: center;
      
      iframe {
        display: block;
      }

      .payment-content-wrapper {
        display: none;
      }
    }

    &.error {
      display: flex;
      min-height: 90px;
      justify-content: center;
      align-items: center;

      iframe {
        display: none;
      }

      .payment-content-wrapper {
        display: flex;
      }
    }

    &.resize-and-purchase {
      min-height: 90px;
      height: 450px;
      display: block;

      .payment-content-wrapper {
        display: none;
      }

      iframe {
        display: block;
      }
    }
  }
}


.user-email{
  .email{
    font-weight: bold;
  }
  .instructions {
    font-size: 13px;
    color: $third-color-blue;
  }
  a{
    color: #557b83;
  }
  >div:first-child{
    >span:first-child{
      padding-right: 5px;
    }
  }
}

@media only screen and (max-width: 576px){
  .notd-table-header{
    min-width: 800px;
  }
  .notd-table-row{
    min-width: 800px;
  }
}

.table-holder {
  @include max-tablet-width() {
    overflow: auto;
  }
}

.profile-avatar {
  height: 202px;
  width: 202px;
  margin: 25px 0 0 0;
  @include radius(50%);
  overflow: hidden;
  position: relative;

  img {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 50%;
    min-width: 100%;
    object-fit: cover;
    @include transform(translate(-50%, 0));
    @include radius(0px);
  }

  @include max-phone-width() {
    height: 170px;
    width: 170px;
    margin: 0 auto;
  }
}

.notd-profile {
  .title {
    margin: 0px;
    padding: 0px 0px 10px 0px;
    color: #ad3a3a;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
  .location, .since{
    font-weight: bold;
    font-size: 14px;
    span{
      padding-right: 4px;
    }
  }
  .location{
    text-align: right;
  }
  .earnings-col{

    display: flex;
    align-items: center;
    justify-content: space-between;

    div{
      padding-right: 4px;
    }
  }
  .earnings-row{
    display: flex;
    align-items: center;
  }
}
