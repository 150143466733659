@import '../../scss/variables.scss';

$box-comment-title-height: 24px;
$box-description-height: 120px;
$box-header-height: 64px;

profile-comment-box {
  @media only screen and (min-width: 768px) {
    width: 49.4%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  .profile-comment-box {
    position: relative;
  }

  .search-item {
    width: 100%!important;
    border: 1px solid #d6d6d6;
    @include box-shadow(rgba(0, 0, 0, 0.05) 0 0 20px 0);
    @include radius(.25rem);
  }
  .spin-container{
    padding-top: 20px;
  }

  .search-item-content{
    padding: 10px;
  }

  .thread-container{
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .title2{

    padding-top: 2px;

    flex-basis: $box-comment-title-height;
    flex-shrink: 0;
    flex-grow: 0;

    font-size: 14px;
    color: #557b83;

    display: flex;
    justify-content: space-between;

    .author1{
      @include simpleEllipsis();
    }

    .date1{
      flex-basis: 100px;
      flex-shrink: 0;
      padding-left: 4px;
        flex-grow: 1;
      /* display: flex; */
      text-align: right;
    }
  }

  .search-item-header {

    height: $box-header-height;
    display:flex;
    border-bottom: 1px solid #dddddd;

    h3{
      @include multiLineEllipsis(1.3em, 2, white);
      word-break: break-word;
    }
  }

  .description{
    min-height: 1.3em * 5;
    @include multiLineEllipsis(1.3em, 5, white);
    word-break: break-word;

  }

}
