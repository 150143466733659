@import '../../scss/variables.scss';

:host {
  .file-info-message {
    font-size: 14px;

    .ng-fa-icon {
      margin: 0 3px;
    }

    span {
      color: $third-color-blue;
    }

    a {
      color: $third-color-blue;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
