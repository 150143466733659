// Variables
$gridWidth: auto;

// Fonts
$titilliumFont: 'Titillium Web', sans-serif;
$cantarellFont: 'Cantarell', sans-serif;
$merriweatherFont: 'Merriweather', serif;
$robotoFont: 'Roboto', serif;

:root {
  --font-family-palatino: Palatino, Georgia, 'Times New Roman', Times, serif;
  --font-family-georgia: Georgia, "Times New Roman", Times, serif;
  --font-family-titillium: 'Titillium Web', sans-serif;
  --font-family-cantarell: 'Cantarell', sans-serif;
  --font-family-roboto: 'Roboto', sans-serif;

  --color-green: #39aea9;
}

// Colors
$primary-color-black: #000;
$secondary-color-carmine: #ad3a3a;
$third-color-blue: #557b83;
$fourth-color-keppel: #39aea9;
$fifth-color-grey-don: #5b5151;
$sixth-color-content: #423230;
$background-gray: #f7f7f7;
$school-bus-yellow: #ffd900;
$main-background-color: #f5f5f5;

// Mixins
@mixin transform($props) {
  -webkit-transform: $props;
  -moz-transform: $props;
  -ms-transform: $props;
  -o-transform: $props;
  transform: $props;
}

@mixin transition($props...) {
  $result: ();

  @for $i from 1 through length($props) {
    $prop: nth($props, $i);
    $result: append($result, $prop);

    @if $i != length($props) {
      $result: append($result, unquote($string: ","));
    }
  }

  -webkit-transition: $result;
  -moz-transition: $result;
  -ms-transition: $result;
  -o-transition: $result;
  transition: $result;
}

@mixin transitionProperty($props) {
  -webkit-transition-property: $props;
  -moz-transition-property: $props;
  -ms-transition-property: $props;
  -o-transition-property: $props;
  transition-property: $props;
}

@mixin radius($props) {
  -webkit-border-radius: $props;
  -moz-border-radius: $props;
  -ms-border-radius: $props;
  -khtml-border-radius: $props;
  -o-border-radius: $props;
  border-radius: $props;
}

@mixin box-sizing() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin background-size($type) {
  background-size: $type;
  -moz-background-size: $type;
  -webkit-background-size: $type;
  -o-background-size: $type;
  -ms-background-size: $type;
}

@mixin clip-path($props) {
  -webkit-clip-path: $props;
  clip-path: $props;
  -moz-clip-path: $props;
  -o-clip-path: $props;
  -ms-clip-path: $props;
}

@mixin animation($props) {
  -webkit-animation: $props;
  -moz-animation:    $props;
  -o-animation:      $props;
  animation:         $props;
}

@mixin scale($props) {
  -webkit-transform: scale($props);
  -moz-transform:    scale($props);
  -o-transform:      scale($props);
  transform:         scale($props);
}


@mixin box-shadow($props) {
  -webkit-box-shadow: $props;
  -moz-box-shadow: $props;
  box-shadow: $props;
}

$max-phone-width: 'only screen and (max-width : 736px)';
@mixin max-phone-width() {
  @media #{$max-phone-width} {
    @content;
  }
}

$max-tablet-width: 'only screen and (max-width : 772px)';
@mixin max-tablet-width() {
  @media #{$max-tablet-width} {
    @content;
  }
}

$ipad-pro-width: 'only screen and (max-width : 1024px)';
@mixin ipad-pro-width() {
  @media #{$ipad-pro-width} {
    @content;
  }
}

@mixin from-768-to-1024() {
  @media only screen and (min-width : 768px) and (max-width : 1024px) {
    @content;
  }
}

@mixin max-w-1024() {
  @media #{$ipad-pro-width} {
    @content;
  }
}

$max-small-tablet-width: 'only screen and (max-width : 972px)';
@mixin max-small-screen-width() {
  @media #{$max-small-tablet-width} {
    @content;
  }
}

$followed-streams-breakpoint-width: 'only screen and (max-width: 991px)';
@mixin followed-streams-breakpoint-width() {
  @media #{$followed-streams-breakpoint-width} {
    @content;
  }
}

$large-screen-width: 'only screen and (min-width : 1460px)';
@mixin large-screen-width() {
  @media #{$large-screen-width} {
    @content;
  }
}

@mixin only-ipad-vertical() {
  @media only screen and (min-width: 700px) and (max-width: 780px) {
    @content;
  }
}

@mixin simpleEllipsis() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: normal;
  word-break: normal;
  hyphens: auto;
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){

  /*Thanks to http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/*/

  $ellipsisW: 16px;

  overflow: hidden;
  position: relative;

  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;

  text-align: left;

  margin-right: 0em;
  padding-right: $ellipsisW;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 5px;

    width: $ellipsisW;
    height: $ellipsisW;

    font-size: $ellipsisW;
    font-weight: bold;
    line-height: $ellipsisW;
    letter-spacing: 1px;
    background: transparent;
    z-index:1;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;

    width: $ellipsisW;
    height: $lineHeight * $lineCount;

    margin-top: 0;
    background: $bgColor;
    z-index:2;
  }
}

