@import './variables.scss';

// content styles in quill editor (creator) + note view
.ql-editor {
  .p-hovered {
    position: relative;
    background-color: #dee2e652;
  }

  .content-paragraph {
    clear: both;
    font-style: italic;
    background-color: #fafafa9c !important;
    border-top: 1px solid #80808033;
    border-bottom: 1px solid #80808033;
    margin: 7px 0;
    padding: 5px;
  }
}

// styles for note view
.main-note-view__content__current {
  &__wrapper {
    .content-paragraph {
      clear: both;
      font-style: italic;
      background-color: #fafafa9c !important;
      border-top: 1px solid #80808033;
      border-bottom: 1px solid #80808033;
      margin: 7px 0 15px;
      padding: 5px;
    }
  }
}

