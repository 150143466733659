@import '../../scss/variables.scss';

notd-id-doc, notd-tax-doc {
  button {
    height: 40px;
  }

  uploader-field {
    margin-bottom: 10px;
  }

  .doc-spin {
    padding-bottom: 10px;
  }

  .uploader-label{
    display: none;
  }
}

:host {
  .file-info-message {
    font-size: 14px;

    fa-icon {
      margin: 0 3px;
    }

    span {
      color: $third-color-blue;
    }

    a {
      color: $third-color-blue;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .notd-doc-uploader {
    h6 {
      margin: 25px 0 0;
    }
  }
}
