@import './variables.scss';

// Notd Theme that is covering most of the bootstrap code in one place
// Replacing many elements from bootstrap library that we can make ourselfe
// Elements type:
// - buttons
// - panel-box (header, body, footer) -> automaticaly styled
// - tables
// - labels
// - forms
// - inputs
// - textareas
// - selects
// - margins
// - paddings

.notd {
    &__grid {
      display: flex;
      flex: 1 0 auto;

      &.wrapper {
        &--centered {
          margin: 0 auto;
          width: 1140px;

          @include max-w-1024() {
            width: auto;
          }

          @include max-phone-width() {
            width: auto;
          }
        }

        &--organized {
          margin: 85px auto 30px !important;

          @include max-w-1024() {
            margin: 75px 15px 10px !important;
          }

          @include max-phone-width() {
            margin: 116px 15px 30px !important;
          }
        }
      }
    }

    &__full-vh {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100%;
    }

    &__headline {
      font-family: var(--font-family-palatino);

      &--h2 {
        font-size: 28px;
        font-weight: normal;
      }
    }

    &__button {
      padding: 4px 10px;
      color: #fff;
      text-align: center;
      font-size: .9em;
      display: inline-block;
      border: 0 none;
      margin: 5px;
      @include radius(5px);
      @include transition(all .25s);
      outline: 0;

      &--v2 {
        background-color: transparent;
        font-family: var(--font-family-cantarell);
        text-transform: uppercase;
        color: #757575;
        font-size: 0.5em;
        border: 0 none;
        padding: 7px 8px;
        cursor: pointer;
        border: 1px solid #757575;
        @include radius(10px);

        &:hover {
          color: white;
          border: 1px solid #202020;
          background-color: #202020;
        }

        &.bg--transparent {
          background-color: transparent;
        }

        &.border--green {
          border: 1px solid #39aea9;
          color: #39aea9;

          &:hover {
            border: 1px solid #39aea9;
            background-color: #39aea9;
            color: white;
          }
        }

        &.button--black {
          color: white;
          border: 1px solid #202020;
          background-color: #202020;

          &:hover {
            color: #757575;
            background-color: transparent;
          }
        }

        &.button--green {
          color: white;
          border: 1px solid #39aea9;
          background-color: #39aea9;

          &:hover {
            border: 1px solid #202020;
            background-color: #202020;
          }
        }

        &.button--white {
          color: black;
          background-color: white;
          border: 1px solid rgb(214 214 214 / 31%);
    
          &:hover {
            background-color: rgba(228, 228, 228, 0.73);
          }
        }

        &.text-transform-lowercase {
          text-transform: lowercase;
        }

        &.text-transform-capitalize {
          text-transform: capitalize;
        }
      }

      &:focus {
        outline: none;
      }
  
      &.large {
        font-size: 1.2em;
        padding: 6px 8px;
      }

      &.large-2x {
        font-size: 1.2em;
        padding: 10px 12px;
      }
  
      &:disabled {
        background-color: rgba(228, 228, 228, 0.73) !important;
      }
  
      &.button-black {
        background-color: black;
  
        &:hover {
          background-color: rgba(115, 115, 115, 0.73);
        }
      }
  
      &.button--white {
        color: black;
        background-color: white;
        border: 1px solid #d6d6d6ba;
  
        &:hover {
          background-color: rgba(228, 228, 228, 0.73);
        }
      }
  
      &.button--blue {
        background-color: $fourth-color-keppel;
  
        &:hover {
          background-color: rgba(192, 192, 192, 0.73);
        }
      }
  
      &.button--red {
        background-color: $secondary-color-carmine;
  
        &:hover {
          background-color: rgba(192, 192, 192, 0.73);
        }
      }

      &.button--black {
        color: white;
        background-color: black;
  
        &:hover {
            color: black;
            background-color: rgba(228, 228, 228, 0.73);
        }
      }
  
      &.loading {
        &:before {
          margin: 0 7px;
          width: 18px;
          height: 18px;
          content: "";
          background-size: cover;
        //   background-image: url('./assets/loading-circle.svg');
          animation: rotating 1.2s infinite linear;
        }
      }
  
      &.width {
        &--md {
          min-width: 130px;
        }
      }
    }
  
    &__table {
      width: 100%;
      border: 0;
  
      thead {
        tr {
          border-bottom: 1px solid #d6d6d6ba !important;
  
          &:hover {
            background: transparent !important;
          }
  
          th {
            font-size: 1.1em;
            padding: 8px 0;
            text-align: center;
            vertical-align: middle;
          }
        }
      }

      tr {
        border-bottom: 1px solid #d6d6d6ba;
  
        @include max-phone-width() {
          display: grid;
        }
  
        &:last-child {
          border-bottom: 0 none;
        }
  
        td {
          vertical-align: middle;
          text-align: center;
          padding: 10px;
        }
      }
  
      &.tr-hovered {
        tr {
          @include transition(all .25s);
  
          &:hover {
            background-color: #d3d3d31f;
          }
        }
      }
  
      &.tr-pointer {
        cursor: pointer;
      }
  
      &__actions {
        text-align: right;
  
        button {
          padding: 4px 10px;
          color: #fff;
          text-align: center;
          display: inline-block;
          border: 0 none;
          margin: 5px;
          @include radius(5px);
          @include transition(all .25s);
  
          &:disabled {
            background-color: rgba(228, 228, 228, 0.73) !important;
          }
  
          &.button--black {
            background-color: black;
  
            &:hover {
              background-color: rgba(115, 115, 115, 0.73);
            }
          }
  
          &.button--white {
            color: black;
            border: 1px solid #d6d6d6ba;
  
            &:hover {
              background-color: rgba(228, 228, 228, 0.73);
            }
          }
  
          &.button--blue {
            background-color: #17a5ae;
  
            &:hover {
              background-color: rgba(192, 192, 192, 0.73);
            }
          }
  
          &.button--red {
            background-color: red;
          }
        }
      }
    }

    &__image-wrapper {
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        @include transform(translate(-50%, -50%));
      }

      &.circle {
        @include radius(50%);
      }

      &.image {
        &-40-40 {
          width: 40px;
          height: 40px;
        }
        &-60-60 {
          width: 60px;
          height: 60px;
        }
        &-80-80 {
          width: 80px;
          height: 80px;
        }
        &-120-120 {
          width: 120px;
          height: 120px;
        }
      }
    }
  
    &__label {
      padding: 4px 10px;
      @include radius(14px);
      color: #fff;
      text-align: center;
      display: inline-block;
  
      &.label {
        &--active, &--red {
          background-color: red;
        }
  
        &--blue {
          background-color: #17a5ae;
        }
  
        &--white {
          background-color: #fff;
          color: #000;
        }
  
        &--black {
          background-color: black;
        }
  
        &--disabled {
          background-color: lightgrey;
        }
      }

      &.large {
        font-size: 1.2em;
      }
    }
  
    &__input {
      font-family: var(--font-family-titillium);
      line-height: 2em;
      padding: 5px;
      border: 1px solid #d6d6d6ba;
      min-width: 120px;
      @include radius(5px);
  
      &.input--error {
        border: 1px solid red;
      }
    }
  
    &__textarea {
      font-family: var(--font-family-titillium);
      line-height: 2em;
      padding: 5px;
      border: 1px solid #d6d6d6ba;
      @include radius(5px);
      min-height: 140px;
  
      &.input--error {
        border: 1px solid indianred;
      }
    }
  
    &__select {
      font-family: var(--font-family-titillium);
      line-height: 2em;
      padding: 5px;
      border: 1px solid #d6d6d6ba;
      background: transparent;
      height: 40px;
      min-width: 120px;
      @include radius(5px);
  
      &.input--error {
        border: 1px solid indianred;
      }
    }
  
    &__form {
      display: flex;
      flex-wrap: wrap;
  
      .element-holder {
        padding: 5px 0;
        display: flex;
        flex: 0 100%;
        flex-direction: column;
  
        label {
          font-weight: normal;
        }
  
        input {
          &.half-size {
            width: 50%;
          }
        }
  
        .form-error {
          display: block;
          color: indianred;
          font-size: 1em;
          padding: 2px 0;
        }
      }
    }
  
    &__modal {
      .modal-header {
        display: flex;
        align-items: flex-end;
        background-color: #d3d3d31f;
  
        h3 {
          margin: 0 10px 0 0;
        }
      }
  
      .modal-body {
  
      }
  
      .modal-footer {
        background-color: #d3d3d31f;
      }
    }

    &__panel-box {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border: 1px solid #ddd;
      margin: 10px 0;
      @include box-shadow(0 1px 1px rgba(0,0,0,.05));
      @include radius(5px);
  
      .header, .footer {
        color: #333;
        padding: 10px;
        background-color: #f5f5f5;
      }
  
      .header {
        border-bottom: 1px solid #ddd;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        h3 {
          font-size: 1.25em;
          color: #333;
          margin: 0;
        }
      }
  
      .body {
        padding: 10px;
      }
  
      .footer {
        border-top: 1px solid #ddd;
        -webkit-border-bottom-right-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomright: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    &__subpage-title {
      font-size: 1.8em;
      color: #4a4a4a;
      margin: 0;
      padding: 0;
    }

    &__m {
      &-0 {
        margin: 0 !important;
      }
      &-0-auto {
        margin: 0 auto!important;
      }
      &-t {
        &-0 {
          margin-top: 0 !important;
        }
        &-10 {
          margin-top: 10px !important;
        }
        &-15 {
          margin-top: 15px !important;
        }
        &-20 {
          margin-top: 20px !important;
        }
        &-40 {
          margin-top: 40px !important;
        }
      }
      &-b {
        &-0 {
          margin-bottom: 0 !important;
        }
        &-5 {
          margin-bottom: 5px !important;
        }
        &-10 {
          margin-bottom: 10px !important;
        }
        &-15 {
          margin-bottom: 15px !important;
        }
        &-20 {
          margin-bottom: 20px !important;
        }
      }
      &-l {
        &-0 {
          margin-left: 0 !important;
        }
        &-10 {
          margin-left: 10px !important;
        }
      }
      &-r {
        &-5 {
          margin-right: 5px !important;
        }
        &-10 {
          margin-right: 10px !important;
        }
        &-20 {
          margin-right: 20px !important;
        }
      }
    }

    &__p {
      &-10 {
        padding: 10px !important;
      }
      &-l {
        &-0 {
          padding-left: 0 !important;
        }
        &-10 {
          padding-left: 10px !important;
        }
      }
      &-r {
        &-10 {
          padding-right: 0 !important;
        }
        &-10 {
          padding-right: 10px !important;
        }
      }
    }

    &__width {
      &-100p {
        width: 100%;
      }
    }

    &__link {
      color: $fourth-color-keppel;
      text-decoration: none;

      &:hover {
        color: grey;
      }
    }

    &__text {
      &--blue {
        color: $fourth-color-keppel;
      }

      &--strong {
        font-weight: bold;
      }

      &--centered {
        text-align: center !important;
      }
    }

    &__datepicker {
      font-weight: normal;
      background-color: #fff;
      border: 1px solid #ddd;
      padding: 8px;
      margin-top: 5px;
      @include radius(5px);

      .bs-datepicker-head {
        text-align: center;

        button {
          background-color: $fourth-color-keppel;
          color: #fff;
          padding: 3px 6px;
          display: inline-block;
          border: 0 none;
          @include radius(5px);

          &.previous, &.next {
            background-color: black;
            padding: 3px 8px;
          }

          &:hover {
            background-color: grey;
          }
        }
      }
    }

    &__display {
      &--flex {
        display: flex;
      }
      &--inline-flex {
        display: inline-flex;
      }
    }
  }
  
  @-webkit-keyframes rotating {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes rotating {
    100% {
      transform: rotate(360deg);
    }
  }