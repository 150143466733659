/* You can add global styles to this file, and also import other style files */
/*@import 'lib/font-awesome/css/font-awesome.min.css';*/
/* ------------- [START Color variables] ------------ */
/* ------------- [END Color variables] -------------- */
/* ------------- [START FONT variables] ------------- */
/* ------------- [END END variables] ------------- */
/* ------------- [START SCREEN variables] ------------- */
/* ------------- [END SCREEN variables] ------------- */
/* ------------- [START Animation variables] ------------ */
/* ------------- [END Animation variables] ------------ */

::selection {
  background: #006cff;
  color: #fff;
}

.news-section {
  position: relative;
  width: 100%;
  min-height: 700px;
  overflow: hidden;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .news-section {
    min-height: 500px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .news-section {
    min-height: 620px;
  }
}
.news-section .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.news-section .bg img {
  width: 100%;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .news-section .bg img {
    width: 380%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .news-section .bg img {
    width: 200%;
  }
}
.news-section .content {
  padding: 70px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .news-section .content {
    padding: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .news-section .content {
    padding: 30px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .news-section .content {
    padding: 40px;
  }
}
.news-section .content h2 {
  color: white;
  font-size: 40px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .news-section .content h2 {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 10px;
  }
}
.news-section .content p {
  color: white;
  padding: 10px;
  font-size: 20px;
  padding-right: 20%;
  padding-left: 4%;
  font-weight: 100;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .news-section .content p {
    font-size: 14px;
    padding-right: 0;
    padding-left: 0;
  }
}
.news-section .content .form-signup {
  width: 60%;
  margin: 0 auto;
  margin-top: 30px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .news-section .content .form-signup {
    width: 100%;
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .news-section .content .form-signup {
    width: 80%;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .news-section .content .form-signup {
    width: 80%;
    margin-top: 50px;
  }
}
.news-section .content .form-signup h2 {
  margin-bottom: 0;
}
.news-section .content .form-signup a {
  font-size: 30px;
  text-decoration: none;
  color: #3aada8;
}
.news-section .content .form-signup a:focus {
  outline: none;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .news-section .content .form-signup a {
    font-size: 20px;
  }
}
.news-section .content ul {
  list-style: none;
  font-size: 28px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .news-section .content ul {
    font-size: 15px;
    padding: 0;
    margin-top: 10px;
  }
}
.news-section .content ul li {
  color: white;
}
.news-section .content ul li a {
  color: #3aada8;
  text-decoration: none;
}
.footer-section {
  margin-top: 15px;
  padding: 15px 0;
  background: #eaeaea;
}
.footer-section a {
  text-decoration: none;
  padding: 5px;
  color: #585858;
}
.footer-section p {
  font-size: 18px;
  margin-bottom: 0;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer-section p {
    font-size: 10px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer-section p span {
    display: block;
    text-align: center;
  }
}
.form-box-signin {
  margin-top: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .form-box-signin {
    margin-top: 20px;
  }
}
.form-box-signin .form-control {
  background: white;
  border: none;
  border-radius: 0;
  height: 50px;
  margin: 5px 0;
}
.form-box-signin .half-box {
  width: 49%;
  display: inline-block;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .form-box-signin .half-box {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-box-signin .half-box {
    width: 48%;
  }
}
.form-box-signin .half-box:nth-child(2) {
  margin-right: 8px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-box-signin .half-box:nth-child(2) {
    margin-right: 17px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .form-box-signin .half-box:nth-child(2) {
    margin-right: 26px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1279px) {
  .form-box-signin .half-box:nth-child(2) {
    margin-right: 7px;
  }
}
.form-box-signin .submit {
  width: 100%;
  background: #ad3a3a;
  color: white;
  margin-top: 4px;
  font-size: 26px;
  cursor: pointer;
}
.login-modal .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
}
.login-modal .modal-header button.close {
  margin-top: -30px;
}
.login-modal .form-box-signin {
  margin-top: 0;
}
.login-modal .form-box-signin .form-control {
  border: 1px solid #f1f2f3;
}
.login-modal .form-box-signin .half-box {
  width: 100%;
}
.login-modal .form-box-signin p {
  margin-top: 10px;
  font-size: 14px;
}
.login-modal .form-box-signin p a {
  text-decoration: none;
  color: #585858;
}
.profile-section {
  padding: 20px;
  height: 190px;
  position: relative;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .profile-section {
    margin-bottom: 180px;
  }
}
.profile-section .bg {
  position: absolute;
  z-index: 0;
  height: 190px;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
}
.profile-section .bg img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.profile-section .fg {
  position: relative;
  z-index: 1;
}
.profile-section .profile-picture {
  border-radius: 50%;
  border: 5px solid #3aada8;
  width: 200px;
  position: relative;
  top: 50px;
  margin: 0 auto;
  display: block;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .profile-section .profile-picture {
    width: 150px;
    top: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .profile-section .profile-picture {
    width: 200px;
    top: 50px;
  }
}
.profile-section .name {
  position: relative;
  top: 120px;
  color: white;
  font-size: 30px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .profile-section .name {
    top: 0;
    margin-top: 70px;
    text-align: center;
    color: #3aada8;
  }
}
.profile-section .name .edit {
  background: #3aada8;
  color: white;
  font-size: 16px;
  margin-left: 10px;
  border-radius: 4px;
  border: none;
  padding: 8px 10px;
  position: inherit;
  top: -6px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .profile-section .name .edit {
    margin-top: 15px;
    margin-left: 0;
    display: block;
    margin: 15px auto;
  }
}
.profile-section .name .edit:hover {
  background: white;
  color: #3aada8;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.item-box {
  text-align: center;
}
.payment-item {
  margin: 15px -10px;
  border-bottom: 1px solid #f1f2f3;
  padding: 0px 10px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .payment-item {
    padding-bottom: 20px;
  }
}
.payment-item .ico {
  font-size: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .payment-item .ico {
    font-size: 24px;
  }
}
.payment-item .check {
  font-size: 16px;
  color: white;
  margin-top: 5px;
  padding: 7px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .payment-item .check {
    font-size: 10px;
  }
  .payment-item .check i {
    display: none;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .payment-item .check i {
    display: none;
  }
}
.payment-item .approved {
  background: #3aada8;
}
.payment-item .pending {
  background: #c7c7c7;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .payment-item .quantity h3 {
    font-size: 16px;
  }
  .payment-item .quantity h6 {
    font-size: 12px;
    margin-bottom: 11px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .payment-item .quantity h6 {
    margin-bottom: 8px;
  }
}
.noti-item {
  margin: 15px -10px;
  border-bottom: 1px solid #f1f2f3;
  padding: 3px 10px;
}
.noti-item a {
  text-decoration: none;
  color: #585858;
}
.noti-item .thumbnail {
  width: 47px;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .noti-item .thumbnail {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .noti-item .thumbnail {
    width: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .noti-item .thumbnail {
    width: 100%;
  }
}
.noti-item .head {
  font-size: 18px;
  border: none;
  padding-left: 0;
  margin: 0;
  margin-bottom: -8px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .noti-item .head {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .noti-item .head {
    font-size: 17px;
  }
}
.noti-item p {
  margin-bottom: 0;
  color: #b9b9b9;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .noti-item p {
    font-size: 10px;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .noti-item p {
    font-size: 12px;
  }
}
.profile-sidebar {
  margin-top: 20px;
}
.profile-sidebar .head {
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .profile-sidebar .head {
    font-size: 18px;
  }
}
.profile-sidebar .item {
  margin: 10px 0;
}
.profile-sidebar .item a {
  text-decoration: none;
  color: #585858;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .profile-sidebar .item a {
    font-size: 12px;
  }
}
.profile-sidebar .item img {
  width: 40px;
  margin-right: 10px;
}
.profile-sidebar .item span {
  margin-top: 5px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .profile-sidebar .item span {
    margin-top: 12px;
  }
}
.profile-sidebar .btn {
  width: 100%;
  display: block;
  text-align: center;
  background: #3aada8;
  margin: 10px 0px;
  color: white;
  border: 2px solid #3aada8;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.profile-sidebar .btn:hover {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  background: white;
  color: #3aada8;
}
.profile-sidebar-first {
  margin-top: 100px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .profile-sidebar-first {
    margin-top: 20px;
  }
}
.tab-menu .form-box-signin {
  padding: 10px 20%;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .tab-menu .form-box-signin {
    padding: 10px;
  }
}
.tab-menu .form-box-signin .form-group {
  position: relative;
}
.tab-menu .form-box-signin .form-control {
  border: 1px solid #f1f2f3;
  margin: 10px 0px;
}
.tab-menu .form-box-signin .submit {
  font-size: 20px;
  background: #3aada8;
}
.tab-menu .change-password {
  padding: 10px 0;
  margin-top: 0;
}
.tab-menu .personal-data {
  margin-top: 10px;
  margin-bottom: 10px;
}
.tab-menu .personal-data .stream-pause {
  border: 1px solid #f1f2f3;
  border-radius: 4px;
  padding: 10px;
  font-size: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-menu .personal-data .stream-pause {
    font-size: 12px;
  }
}
.tab-menu .personal-data .btn {
  padding: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-menu .personal-data .btn {
    font-size: 10px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .tab-menu .personal-data .btn {
    font-size: 11px;
    height: 40px;
  }
}
.tab-menu .personal-data img {
  width: 100%;
}
.tab-menu .personal-data p {
  margin-bottom: 12px;
  font-size: 20px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .tab-menu .personal-data p {
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-menu .personal-data p {
    font-size: 12px;
    margin-top: 6px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .tab-menu .personal-data p {
    font-size: 17px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .tab-menu .personal-data .active-status {
    text-align: left;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .tab-menu .personal-data .active-status {
    margin-left: -17px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1279px) {
  .tab-menu .personal-data .active-status {
    margin-left: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-menu .personal-data .rating-star {
    display: block;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .tab-menu .personal-data .rating-star {
    display: inline-block;
  }
}
.tab-menu .personal-data .form-upload {
  position: relative;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .tab-menu .personal-data .form-upload {
    text-align: center;
  }
}
.tab-menu .personal-data .form-upload input {
  position: absolute;
  cursor: pointer;
  width: 80px;
  height: 44px;
  opacity: 0;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-menu .personal-data .form-upload input {
    width: 60px;
    height: 38px;
  }
}
.tab-menu .personal-data .form-upload span {
  background: #3aada8;
  color: white;
  font-size: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-menu .personal-data .form-upload span {
    font-size: 12px;
  }
}
.tab-menu .bank-account {
  padding: 10px;
  margin-top: 0;
}
.tab-menu .team-form {
  padding: 10px;
  margin-top: 0;
}
.tab-menu .team-form .submit {
  margin-top: 10px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-menu .team-form .submit {
    font-size: 16px;
  }
}
.tab-menu .team-form .btn-add {
  display: inline-block;
  width: auto;
}
.tab-menu .team-form label.ico {
  position: absolute;
  top: 12px;
  left: 13px;
  color: #717171;
  border-right: 1px solid #717171;
  padding-right: 10px;
}
.tab-menu .team-form .form-control.icon {
  padding-left: 40px;
}
.tab-menu .admin-list {
  padding: 10px;
  margin-top: -30px;
}
.tab-menu .admin-list li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #f1f2f3;
}
.tab-menu .admin-list li .btn-delete {
  color: white;
  float: right;
  opacity: 0;
  margin-top: -27px;
  padding: 3px 5px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
@media only screen and (min-width: 415px) and (max-width: 736px) {
  .tab-menu .admin-list li .btn-delete {
    opacity: 1;
  }
}
.tab-menu .admin-list li:hover .btn-delete {
  opacity: 1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.tab-menu .admin-list li:first-child {
  border-bottom: none;
  margin-bottom: -10px;
}
.tab-menu .admin-list li p {
  margin-bottom: 0;
}
.tab-menu .team-account {
  margin-top: -10px;
  padding-left: 30px;
}
.tab-menu .team-account li {
  border: none;
}
.tab-menu .submit {
  background: #3aada8;
  color: white;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-menu .box-solid .item-box h2 {
    font-size: 28px;
  }
  .tab-menu .box-solid .item-box h5 {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .tab-menu .box-solid .item-box h2 {
    font-size: 28px;
  }
  .tab-menu .box-solid .item-box h5 {
    font-size: 12px;
  }
}
body {
  background: #f1f2f3;
}
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
.stream-section .slide {
  outline: none;
}
.carousel-item.active {
  display: block;
}
.modal-content {
  border-radius: 0;
  border: none;
}
.box-solid {
  background: white;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 20px;
}
.box-solid .head {
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f2f3;
  margin: 0px -10px;
  padding-left: 15px;
  font-size: 24px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .box-solid .head {
    font-size: 18px;
  }
}
.box-solid .sub-head {
  margin-top: 15px;
  font-size: 22px;
}
.box-solid .remark {
  margin: 15px 0;
  font-size: 1em;
}
.btn-filter {
  width: 100%;
  display: block;
  text-align: center;
  background: #3aada8;
  margin: 10px 0px;
  color: white;
  border: 2px solid #3aada8;
  border-radius: 4px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.btn-filter:hover {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  background: white;
  color: #3aada8;
}
.form-box {
  margin: 15px 0;
}
.form-control {
}
.range-bar {
  text-align: center;
}
.range-bar input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
.range-bar input[type=range]:focus {
  outline: none;
}
.range-bar input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: #cb5858;
  border-radius: 25px;
}
.range-bar input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #3aada8;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
.range-bar input[type=range]:focus::-webkit-slider-runnable-track {
  background: #cb5858;
}
.range-bar input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: #cb5858;
  border-radius: 25px;
}
.range-bar input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
  height: 20px;
  width: 20px;
  border-radius: 7px;
  background: #ad3a3a;
  cursor: pointer;
}
.range-bar input[type=range]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 39px 0;
  color: transparent;
}
.range-bar input[type=range]::-ms-fill-lower {
  background: #ad3a3a;
  border-radius: 50%;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
}
.range-bar input[type=range]::-ms-fill-upper {
  background: #ad3a3a;
  border-radius: 50%;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
}
.range-bar input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ad3a3a;
  cursor: pointer;
}
.range-bar input[type=range]:focus::-ms-fill-lower {
  background: #ad3a3a;
}
.range-bar input[type=range]:focus::-ms-fill-upper {
  background: #ad3a3a;
}
.range-bar .ng-valid {
  border: none;
}
.range-bar .ng-dirty.ng-valid {
  border: none;
}
.navbar-section {
  width: 100%;
  padding: 10px 15px;
  margin: 0;
  background: white;
}
.navbar-section .navbar-brand img {
  width: 220px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .navbar-section .navbar-brand img {
    width: 130px;
  }
}
.navbar-section .navbar-nav {
  display: block;
  text-align: right;
  width: 100%;
  margin-top: -20px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .navbar-section .navbar-nav {
    position: absolute;
    width: 100%;
    background: white;
    text-align: center;
    z-index: 9;
    left: 0;
    padding: 10px 0;
    margin-top: 0;
  }
}
.navbar-section .navbar-nav .nav-item {
  display: inline-block;
}
.navbar-section .navbar-nav .nav-item .nav-link {
  text-decoration: none;
  color: #3aada8;
  background: none;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.navbar-section .navbar-nav .nav-item .nav-link:hover {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  background: #3aada8;
  color: white;
}
.navbar-section .navbar-nav .nav-item .nav-link:focus {
  outline: none;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .navbar-section .navbar-nav .nav-item button.nav-link {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar-section .navbar-nav .nav-item button.nav-link {
    width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .navbar-section .navbar-nav .nav-item button.nav-link {
    width: auto;
  }
}
.navbar-section .navbar-nav .profile img {
  width: 50px;
  border-radius: 50%;
}
.navbar-section .navbar-nav .profile .btn {
  background: none;
}
.navbar-section .navbar-nav .profile .dropdown-toggle:after {
  display: none;
}
.navbar-section .navbar-nav .notification i {
  color: #3aada8;
  font-size: 18px;
}
.navbar-section .navbar-nav .notification .btn {
  background: none;
  padding: 5px;
  position: relative;
}
.navbar-section .navbar-nav .notification .btn span {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  background: #c32c02;
  right: -6px;
  top: -8px;
  font-size: 14px;
}
.navbar-section .navbar-nav .notification .dropdown-toggle:after {
  display: none;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .navbar-section .navbar-toggler {
    margin-top: 10px;
  }
  .navbar-section .navbar-toggler i {
    color: #3aada8;
  }
}
.main-color {
  color: #3aada8;
}
.stream-thumbnail img {
  background: #3aada8;
}
.title {
  font-size: 2em;
  margin: 40px 0;
  line-height: 38px;
  padding: 0 40px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .title {
    font-size: 1.5em;
    margin: 10px 0;
    line-height: 26px;
    padding: 0;
  }
}
.main-title {
  margin-bottom: 0px !important;
  font-weight: 700;
}
