@import '../../scss/variables.scss';

:host {
  display: flex;
  height: 100%;
  flex-direction: column;

  --font-1: Georgia, Palatino, "Times New Roman", Times, serif;
  --color-blue-1: #39aea9;

  .container {
    flex: 1 0 auto;
  }

  .content-wrapper {
    @include max-tablet-width() {
      overflow: hidden;
    }
  }

  .notd-earnings-page {
    @include max-w-1024() {
      flex-direction: column;
    }

    &__content {
      font-family: var(--font-1);
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  .profile-details {
    display: flex;

    @include max-small-screen-width() {
      flex-wrap: wrap;
    }

    .profile-avatar {
      @include max-small-screen-width() {
        margin: 10px auto 15px;
      }
    }

    .side-details {
      display: flex;
      margin-left: 10px;
      flex: 1 0 auto;

      @include max-small-screen-width() {
        flex: 1 0 auto;
      }

      .user-title, .user-location {
        flex: 1 0;
      }

      .user-location {
        text-align: right;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }

  .stream-earnings {
    margin: 0 auto 20px;
  }

  .earnings-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include max-phone-width() {
      flex-wrap: wrap;
    }

    select {
      flex: 0 0 140px;

      @include max-phone-width() {
        flex: 1 0 auto;
        margin-bottom: 15px;
      }

      @include ipad-pro-width() {
        flex: 0 135px;
        padding: 0 5px;
      }
    }

    .total-earnings {
      @include ipad-pro-width() {
        font-size: .8em;
        font-family: var(--font-1);
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      span {
        color: $fifth-color-grey-don;
        font-size: 1rem;
        display: inline-block;
        margin-right: 15px;
      }

      @include max-phone-width() {
        flex: 1 0 70%;
      }
    }

     button {
       @include max-phone-width() {
         flex: 1 0 30%;
       }
     }

    .transfer-btn {
      height: auto;
      font-size: .8em;
    }
  }
}


