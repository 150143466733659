@import '../../scss/variables.scss';

$box-rating-title-height: 24px;
$box-rating-height: 290px;
$box-header-height: 64px;

profile-rating-box {
  @media only screen and (min-width: 768px) {
    width: 49.4%;
  }

  @include max-tablet-width() {
    width: 100%;
  }

  .profile-rating-box {
    position: relative;

    .head {
      h3 {
        margin-bottom: 0;

        @include max-phone-width() {
          margin-left: 10px;
        }
      }
    }

    .rating-details {
      .date {
        display: block;
      }
    }
  }

  .spin-container {
    padding-top: 20px;
  }
  .search-item {
    width: 100% !important;
    border: 1px solid #d6d6d6;
    @include box-shadow(rgba(0, 0, 0, 0.05) 0 0 20px 0);
    @include radius(.25rem);
  }

  .search-item-content{
    padding: 10px;
  }

  .thread-container{
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .title2 {
    padding-top: 2px;
    flex-basis: $box-rating-title-height;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 14px;
    color: #557b83;
    display: flex;
    justify-content: space-between;

    .author1{
      @include simpleEllipsis();
    }
  }

  .search-item-header {
    height: $box-header-height;
    display:flex;
    border-bottom: 1px solid #dddddd;
    h3{
      @include multiLineEllipsis(1.3em, 2, white);
      word-break: break-word;
    }
  }

  .description-container {
    height: 90px;

    @include max-tablet-width() {
      height: auto;
    }

    .rating-subject{
      font-size: 15px;
      margin: 2px 0;

      @include simpleEllipsis();
    }

    .description {
      font-size: 14px;
      @include multiLineEllipsis(1.3em, 4, white);
      word-break: break-word;

    }

  }
}
