@import '../../scss/variables.scss';

notd-legal-info{
  label {
    position: relative;

    &::after{
      position: absolute;
      top: 4px;
      left: calc(100% + 4px);
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-size: 14px;

    }

    &.valid::after{
      content: "\F058";
      color: rgba(0, 128, 0, 1);
    }

    &.required::after{
      content: "\f069";
      color: rgba(173, 58, 58, 1);
      font-size: 10px;
    }

  }


  input.ng-invalid.ng-dirty, input.ng-invalid.ng-dirty:focus{
    background: rgba(255, 0, 0, 0.10);
  }

  .validation-icon {
    font-size: 18px;
    color: $fourth-color-keppel;

    &.required {
      color: $secondary-color-carmine;
    }
  }
}

