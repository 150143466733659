@import '../../scss/variables.scss';

notd-bank-transfer {
  .notd-fee {
    font-weight: bold;

    span{
      padding-right: 5px;
    }
  }

  .bank-address {
    border-right: 1px solid rgba(91, 81, 81, .5);

    @include max-phone-width() {
      border-right: 0 none;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 16px;
      color: $third-color-blue;
    }

    p {
      margin: 0;
    }
  }

  label{
    font-size: 14px;
    margin: 0;
  }

  .bank-mode-switch{
    button{
      margin: 5px;
    }
  }

  .bank-info {
    width: 100%;

    h3 {
      font-size: 16px;
      color: $third-color-blue;
    }

    p {
      margin: 0;
    }

    .strong {
      font-weight: bold;
    }

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;

      > div {
        word-break: break-word;
      }
    }
  }

  price-field{
    input{
      font-weight: bold;
      font-size: 20px;
    }
  }

  .modal-header{
    background: transparent;
    color: black;
    .modal-title{
      color: black;
    }

    button.close{
      background: transparent;
      opacity: 1;
      //color: white;
      //font-weight: normal;
      //background: #ad3a3a;
      color: black;
      &:hover{
        color: #ad3a3a;
      }

      &[disabled]{
        color: #cbcbcb;
      }
    }
  }

  .amount-line {
    align-items: center;

    .user-balance {
      display: flex;
      align-items: baseline;

      .total-earnings {
        margin: 0 0 0 10px;
      }
    }

    h4 {
      font-weight: normal;
    }
  }

  .transfer-section{
    margin-top: 20px;
    margin-bottom: 10px;
    border-style: none none solid none;

    input.ng-invalid.ng-dirty, input.ng-invalid.ng-dirty:focus{
      background: rgba(255, 0, 0, 0.10);
    }
  }

  h3, h4, h5, h6{
    font-weight: bold;
  }
  .modal-body{
    button{
      min-width: 100px;
    }
  }

  .move-right {
    text-align: right;

    .ml {
      margin-left: 10px;
    }
  }

  .info-box {
    margin: 0 0 15px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
    text-align: center;
    padding: 10px 60px;
    background: rgba(0, 0, 0, 0.02);
    @include radius(5px);

    .ng-fa-icon {
      position: absolute;
      font-size: 40px;
      left: 25px;
      top: 28px;
    }

    p {
      padding: 0;
      margin: 0 0 5px;
    }

    button {
      &:hover {
        background: $third-color-blue;
      }
    }
  }

  .form-buttons {
    margin: 0 15px;

    button {
      margin: 0 10px 0 0;
    }
  }
}

.notd-user-docs-wrapper{
  padding-right: 0;
  margin: 0;
  padding-left: 0;
}

.notd-user-docs{

  display: flex;
  flex-direction: column!important;

  h5, h6{
    position: relative;

    i{
      font-size: 30px;
      color: green;
    }
  }

  >div:last-child{

    display: flex;

    >div{
      flex-grow: 1;
      flex-basis: 50%;


      display: flex;
      flex-direction: row;
      align-content: space-between;
      flex-wrap: wrap;

      >div{
        width: 100%;
        padding: 10px;

      }


    }


    .file-display-container{
      >div.file-displayer{
        flex-basis: calc(100% - 100px);
      }
      >div.file-button{
        flex-basis: 100px;
      }
    }

    @media only screen and (max-width: 991px) {
      flex-direction: column !important;

      .file-display-container{
        > div.file-displayer {
          flex-basis: 100%;
        }
        > div.file-button {
          flex-basis: 100%;
        }
      }
    }
  }

  p {
    padding: 10px 0;
  }

  .notd-doc-uploader {
    uploader-field .file-upload label[for], button{
      height: 34px;
      padding: 0px 10px;
      cursor: pointer;
      line-height: 34px;
    }
    uploader-field {
      margin-bottom: 10px;
    }

    .doc-spin{
      padding-bottom: 10px;
    }

    uploader-field .file-upload label.file-display{
      min-height: 30px;
    }

    p {
      padding: 10px 0;
      margin: 0;
    }
  }
}

.total-earnings{
  color: #557b83;
  font-size: 20px;
  font-weight: bold;
}
