@import '../scss/variables.scss';

:host {
  display: flex;
  height: 100%;
  flex-direction: column;

  --font-1: Georgia, Palatino, "Times New Roman", Times, serif;
  --color-blue-1: #39aea9;

  .container {
    flex: 1 0 auto;
  }

  .content-wrapper {
    @include max-tablet-width() {
      overflow: hidden;
    }
  }

  .notd-team-page {
    @include max-w-1024() {
      flex-direction: column;
    }

    &__content {
      display: flex;
      flex: 1;
      flex-direction: column;

      &__loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px auto;
  
        &__spinner {
          position: relative;
          background: #fff;
          @include radius(10px);
          width: 50px;
          height: 50px;
  
          &:after {
            position: absolute;
            content: '';
            border: 2px solid #444444;
            border-color: #44444478 #44444478 transparent transparent;
            width: 25px;
            height: 25px;
            top: 25%;
            left: 25%;
            @include animation(button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite);
            @include radius(50%);
            @include transform(translate(-25%, -25%));
          }
        }
  
        &__label {
          font-family: $cantarellFont;
          text-transform: uppercase;
          font-size: 0.7em;
          margin: 10px 0 0;
        }
      }

      &__info {
        background: #fff;
        padding: 25px;
        margin: 20px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: var(--font-1);
  
        @include radius(15px);
  
        fa-icon {
          font-size: 2em;
          margin: 0 15px 0 5px;
          color: #3aada8;
        }
  
        p {
          margin: 0;
          padding: 0;
        }
      }

      &__create-team {
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: var(--font-1);
  
        @include radius(15px);
  
        fa-icon {
          font-size: 2.2em;
          margin: 0;
          color: #96a3a3;
        }
  
        p {
          margin: 0;
          padding: 0;
        }

        button {
          font-size: .7em;
          margin: 15px;
        }
      }

      &__team-form {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .team-page {
    &__content {
      font-family: var(--font-1);

      &__table {
        &__title {
          font-family: var(--font-family-georgia);
          font-weight: normal;
          font-size: 1.05em;
          margin: 0 0 12px;
        }

        table {
          margin: 10px 0;
          min-width: 800px;
          font-family: var(--font-1);
          
          thead {
            background-color: #80808012;
      
            tr {
              @include max-phone-width() {
                display: table-row;
              }
      
              th {
                text-align: center;
                padding: 14px 5px;
      
                &.row-costs {
                  width: 200px;
                }
      
                &.row-extend {
                  width: 260px;
                }
      
                &.label {
                  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
                  color: #5d6277;
                  font-size: 0.9em;
                  font-weight: 500;
                  margin: 0 15px 0 0;
                  cursor: pointer;
                }
              }
            }
          }
      
          tbody {
            tr {
              @include max-phone-width() {
                display: table-row;
              }
      
              td {
                .link {
                  color: var(--color-blue-1);

                  &.black--link {
                    color: var(--color-black-1);

                    &:hover {
                      color: grey;
                    }
                  }
                  
                  &:hover {
                    color: black;
                    text-decoration: none;
                  }
                }

                .stream-title-wrapper {
                  display: flex;
                  justify-content: left;
                  align-items: center;
  
                  &__img {
                    margin: 0 10px 0 0;
  
                    img {
                      width: 45px;
                      @include radius(10px);
                    }
                  }
                }

                .stream-status {
                  font-size: .8em;
                  font-weight: bold;
                }

                button {
                  font-size: .65em;
                }
              }
            }
          }
        }
      }

      &__invite {
        &__form {
          .team-page__form__input-wrapper {
            flex-direction: initial;
            align-items: center;
            margin: 0;

            button {
              font-size: .7em;
            }

            input {
              margin: 0 10px;
              width: 240px;
            }
          }

          .team-page__form__input-wrapper__error {
            margin: 0;
          }
        }
      }
    }

    &__form {
      margin: 30px 0;
      font-family: var(--font-1);

      &__input-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 0 15px;

        &__label {
          margin: 0 0 2px;
          font-family: var(--font-family-georgia);
          font-size: 1.25em;
        }

        label {
          margin: 0 0 2px;
          font-size: 1em;
        }

        &__info {          
          color: #777272;
          font-family: var(--font-family-georgia);
          font-size: 0.8em;
          margin: 0px 0 14px;
        }

        &__input, &__textarea {
          font-family: var(--font-family-georgia);
          line-height: initial;
          padding: 8px 5px;
          font-size: .9em;
        }

        &__input {

        }

        &__textarea {
          line-height: 1.3em;

          + .chart-input-counter {
            top: 0px !important;
            right: 0px !important;
          }
        }

        &__error {
          font-size: 0.85em;
          margin: 10px 0;
          display: flex;
          align-items: center;

          svg {
              width: 22px;
              height: 22px;
              margin: 0 8px 0 0;
          }
        }

        &.custom-url {
          label {
            display: flex;
            align-items: center;

            toggle-switch {
              margin: 0 0 0 15px;
            }
          } 
        }

        .custom-url {
          &__input-wrapper {
            display: flex;
            margin: 5px 0;

            input {
              flex: 1;
            }
          }
        }
      }

      &__buttons {
        text-align: left;
        margin: 20px 0;

        button {
          font-size: .75em;
          margin: 0 10px 0 0;

          &:disabled {
            border: 0 none !important;
          }
        }
      }
    }

    &__team-subs-table-wrapper {
      margin: 0 0 30px;
      overflow: auto;

      .team-subs-table {
        width: 100%;
        border: 0;
    
        tr {
          border-bottom: 1px solid #d6d6d6ba;
    
          @include max-phone-width() {
            display: grid;
          }
    
          &:last-child {
            border-bottom: 0 none;
          }
    
          td {
            vertical-align: middle;
            text-align: center;
            padding: 10px;
          }
        }
    
        &__type {
          &__name {
            font-family: var(--font-family-cantarell);
            font-size: .9em;
            font-weight: bold;
            color: black;
            text-transform: uppercase;
          }
        }
    
        &__price {
          &__input {
            font-family: var(--font-family-cantarell);
            font-size: .9em;
            line-height: 1.5em;
            padding: 5px;
            border: 1px solid #d6d6d6ba;
            width: 120px;
            @include radius(5px);
    
            &.input--error {
              border: 1px solid darkred;
            }
          }
    
          &__info {
            display: block;
            padding: 5px 0 0;
            font-size: .7em;
    
            &.info-error {
              color: darkred;
            }
          }
        }
    
        &__status {
          &__label {
            padding: 4px 10px;
            @include radius(14px);
            color: #fff;
            text-align: center;
            display: inline-block;
            font-family: var(--font-family-cantarell);
            font-size: .8em;
            text-transform: uppercase;
    
            &.label {
              &--active {
                background-color: red;
              }
    
              &--disabled {
                background-color: lightgrey;
              }
            }
          }
        }
    
        &__actions {
          text-align: right;
    
          button {
            padding: 6px 10px;
            font-size: .8em;
            color: #fff;
            text-align: center;
            display: inline-block;
            border: 0 none;
            margin: 5px;
            @include radius(15px);
            @include transition(all .25s);
    
            &:disabled {
              background-color: rgba(228, 228, 228, 0.73) !important;
            }
    
            &.button--black {
              background-color: black;
    
              &:hover {
                background-color: rgba(115, 115, 115, 0.73);
              }
            }
    
            &.button--white {
              color: black;
              border: 1px solid #d6d6d6ba;
    
              &:hover {
                background-color: rgba(228, 228, 228, 0.73);
              }
            }
    
            &.button--blue {
              background-color: #17a5ae;
    
              &:hover {
                background-color: rgba(192, 192, 192, 0.73);
              }
            }
    
            &.button--red {
              background-color: red;
            }

            &.enabled--state {
              background-color: #000;
            }
          }
        }
      }
    }
  }
}

notd-team {
  h5 {
    font-weight: bold;
  }

  h2 {
    font-size: .9em;
    font-weight: bold;

    &.underline {
      border-bottom: 1px solid #ced4da;
    }
  }

  .message {
    font-size: .95em;
    margin: -5px 0 10px;
  }

  .validation-icon {
    font-size: 18px;
    color: $fourth-color-keppel;

    &.required {
      color: $secondary-color-carmine;
    }
  }

  .info-message {
    display: flex;
    margin: 0 0 10px;
    padding: 2px 10px;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #ced4da;
    background: rgba(206, 212, 218, 0.1);

    @include radius(5px);

    .ng-fa-icon {
      color: $third-color-blue;
      font-size: 24px;
    }

    p {
      margin: 0 6px;
      font-size: 14px;
    }
  }

  .btn {
    border: 0 none;
    background-color: $third-color-blue;
    color: #fff;
    font-size: 15px;
    margin: 0;

    &:hover {
      background-color: $secondary-color-carmine;
    }

    &.red {
      background-color: $secondary-color-carmine;

      &:hover {
        background-color: $third-color-blue;
      }
    }
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .table-wrapper {
    padding: 0 0 20px;

    @include max-phone-width() {
      overflow: auto;
    }

    table {
      width: 100%;
      border: 1px solid #d6d6d6;
      background-color: #fff;
      margin-bottom: 10px;
      overflow: hidden;
      @include box-shadow(rgba(0, 0, 0, 0.05) 0 0 20px 0);
      @include radius(.25rem);

      @include max-phone-width() {
        width: 800px;
      }

      .link {
        color: $third-color-blue;
      }

      thead {
        th {
          background: #d6d6d67a;
          padding: 3px;
          text-align: center;
        }
      }

      tbody {
        tr {
          @include transition(all .2s ease-out);
          background: none;

          td {
            text-align: center;
            font-size: 0.8rem;
            padding: 3px 1px;
            border-bottom: 1px solid #d6d6d6ba;

            &.note-name {
              max-width: 180px;
            }

            &.location {
              max-width: 140px;
            }

            &.options {
              min-width: 80px;
            }
          }

          &:hover {
            background: #f5f5f5d9;
          }

          .btn {
            padding: 2px 3px;
            margin: 1px 2px;
            font-size: 12px;

            &:hover {
              background: $third-color-blue;
            }
          }

          .tag {
            background: $third-color-blue;
            color: #fff;
            font-size: 11px;
            padding: 2px 5px;
            margin: 1px;
            @include radius(.25rem);
          }
        }
      }
    }

    .show-more {
      .btn {
        font-size: 16px;
        color: white;
        @include transition(all .4s ease-in);

        &:hover {
          background: $third-color-blue;
        }
      }
    }
  }

  team-plans {
    h5 {
      font-size: .9em;
      font-weight: bold;
      border-bottom: 1px solid #ced4da;
    }

    .header-row, .notd-table-header {
      background: #d6d6d67a;
      padding-top: 2px;
      padding-bottom: 2px;

      div {
        color: $primary-color-black !important;
      }
    }
  }

  notd-team-members-table {
    h5 {
      font-size: 1.1em;
      color: $primary-color-black;
      font-weight: bold;
      border-bottom: 1px solid #ced4da;
    }

    .notd-table-header {
      background: #d6d6d67a !important;
      padding-top: 2px;
      padding-bottom: 2px;
      color: $primary-color-black !important;
      align-items: center;
    }
  }

  .invite-form {
    input {
      flex: 0 250px;
      margin-right: 5px;

      @include max-phone-width() {
        flex: auto;
      }
    }
  }
}
