@import '../scss/variables.scss';

$streams-per-row: 3; //Number of stream boxes per row.

$stream-box-height: 240px;
$stream-head-height: 64px;

$stream-box-width: 100% / #{$streams-per-row};
$stream-box-background-color: white;

$stream-box-vertical-gap: 25px;
$stream-box-horizontal-gap: 50px;

$left-column-size: 250px;

:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-lp-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 20px auto;
  width: 960px;

  @include max-w-1024() {
    width: auto;
    flex: 1;
    margin: 85px 15px 30px !important;
  }

  @include max-phone-width() {
    margin: 116px 15px 30px !important;
  }

  .lp-headline-main {
    font-family: var(--font-family-palatino);
    font-size: 1.5em;
    margin: 0 0 20px;
    color: #212529;
    text-decoration: none;

    &:hover {
      color: $fourth-color-keppel;
    }
  }

  .search-bar {
    display: flex;
    flex: 1;
    margin: 0 0 30px 0;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;


    @include max-w-1024() {
      margin: 0 0 15px;
      flex: 1;
    }

    @include max-phone-width() {
      display: none;
    }

    &.show--bar {
      display: flex;
    }

    search-content {
      flex: 1;
      z-index: 9;

      form {
        flex: 100%;
        justify-content: flex-end;

        .input-group {
          width: 280px;

          @include max-w-1024() {
            width: 100%;
          }
        }

        input[type="text"] {
          background-color: #eaeaea;
          @include radius(10px !important);
          @include transition(none !important);
  
          &:focus {
            background-color: #fff;
          }
        }
      }

      .preview--mode {
        &.preview--active {
          form {
            .input-group {
              width: 100%;

              input {
                height: 62px;
                border: 0 none;
                @include transition(none);
              }

              svg {
                top: 16px !important;
              }

              .close-preview-btn {
                top: 14px !important;
                right: 17px !important;
              }
            }
          }

          &:before {
            content: '';
          }
        }

        &:before {
          display: none;
          position: fixed;
          background-color: #f7f7f7c9;
          display: flex;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      } 

      .preview-list-container {
        top: 74px !important;
        border: 0 none !important;
        box-shadow: initial !important;
      }
    }
  }

  .newest-section {
    display: flex;
    flex-direction: column;
    flex: 0 100%;
    margin: 0 0 25px;

    @include max-w-1024() {
      margin: -10px 0 50px
    }

    .headline-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      @include max-w-1024() {
        flex-direction: column-reverse;
        align-items: stretch;
      }

      .lp-headline-main {
        @include max-w-1024() {
          position: relative;
          padding: 0;
          margin: 0 0 5px;
        }
      }
    }
  }

  .featured-notes-section {
    display: flex;
    flex-direction: column;
    flex: 0 100%;
    margin: 0 0 30px;

    @include max-w-1024() {
      margin: 10px 0 40px
    }

    &__headline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 0 0 20px;

      h2 {
        font-family: var(--font-family-palatino);
        font-size: 1.5em;
        margin: 10px 0 0;
        color: #212529;
        text-decoration: none;

        @include max-phone-width() {
          font-size: 1.15em;
        }
      }

      @include max-w-1024() {
        flex-direction: column-reverse;
        align-items: stretch;
      }

      .search-bar {
        margin: 0;
      }

      .lp-headline-main {
        position: absolute;

        @include max-w-1024() {
          position: relative;
          padding: 0;
          margin: 0 0 5px;
        }
      }
    }

    &__wrapper {
      display: flex;
      font-family: var(--font-family-georgia);
      padding: 18px 10px;
      background-color: #ecf0f1;
      @include radius(10px);

      &__note {
        display: flex;
        flex: 1;
        margin: 0;
        padding: 0 8px;
        font-size: .95em;

        @include max-phone-width() {
          padding: 10px 0;
        }

        .note-img {
          display: flex;
          margin: 0 25px 0 0;
          width: 120px;
          height: 120px;
          max-width: 120px;

          @include max-phone-width() {
            margin: 0 15px 0 0;
            width: 60px !important;
            height: 60px !important;
            max-width: 60px !important;
          }
        }

        .note-details {
          flex: 1;

          .tags {
            margin: 3px 0px;
            line-height: .7em;

            .tag {
              text-transform: uppercase;
              font-size: 0.6em;
              display: inline-flex;
              margin: 0 5px 5px 0;
              color: white;
              padding: 3px 5px;
              border-radius: 8px;
              background-color: $fourth-color-keppel;

              &.tag--free-note {
                background-color: #202020;
                border: 1px solid #202020;
                color: white
              }
            }
          }

          .note-title {
            margin: 0 0 5px;
            font-size: 1.1em;
            cursor: pointer;
            color: #212529;
            text-decoration: none;

            &:hover {
              color: $fourth-color-keppel;
              border-bottom: 1px solid grey;
            }

            @include max-phone-width() {
              font-size: 1em;
            }
          }

          .note-description {
            font-size: .9em;
            padding: 0;
            margin: 3px 0 8px;
            color: #777272;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          .other-details {
            display: flex;
            align-items: center;
            margin: 12px 0 0;

            @include max-phone-width() {
              flex-direction: column;
              align-items: start;
            }

            .by-user {
              font-family: var(--font-family-palatino);
              display: flex;
              font-size: .8em;
              color: #3c3c3c;
              margin: 0 15px 0 0;
              align-items: end;
              text-decoration: none;

              .avatar {
                width: 20px;
                height: 20px;
                margin: 0 6px 0 0;
                @include radius(50%);
              }
            }

            .date {
              font-size: .7em;
              color: black;
              margin: 0 0 2px;
              display: none;
            }
          }
        }
      }

      @include max-phone-width() {
        flex-direction: column;
        margin: 0 !important;
        padding: 0 10px;
      }
    }
  }

  .mobile-content {
    font-family: var(--font-family-palatino);
    flex: 100;

    &__loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 100px auto;

      &__spinner {
        position: relative;
        background: #fff;
        @include radius(10px);
        width: 50px;
        height: 50px;

        &:after {
          position: absolute;
          content: '';
          border: 2px solid #444444;
          border-color: #44444478 #44444478 transparent transparent;
          width: 25px;
          height: 25px;
          top: 25%;
          left: 25%;
          @include animation(button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite);
          @include radius(50%);
          @include transform(translate(-25%, -25%));
        }
      }

      &__label {
        font-family: var(--font-family-cantarell);
        text-transform: uppercase;
        font-size: 0.7em;
        margin: 10px 0 0;
      }
    }

    &__headline {
      font-size: 1.15em;
      margin: 8px 0 20px;
    }

    &__latest {
      &__note {
        display: flex;
        padding: 0 0 20px;
        margin: 0 0 20px;
        word-break: break-word;
        border-bottom: 1px solid #0000000d;

        &__details {
          flex: 1;

          &__stream {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &__img ::ng-deep {
              .img-lazy-wrapper {
                margin: 0 6px 0 0;
                height: 24px;
                width: 24px;
              }
            }

            &__title {
              font-size: .5em;
            }

            &__by-user {
              font-size: .5em;
              color: #3c3c3c;
              text-decoration: none;
            }

            &__separator {
              color: grey;
              font-size: .5em;
              margin: 0 5px;
            }

            &__profile-img {
              display: inline-flex;
              width: 24px;
              height: 24px;
              margin: 0 6px 0 0;

              @include radius(50%);
            }
          }

          &__title {
            display: flex;
            font-size: .95em;
            line-height: 1.2;
            font-weight: bold;
            font-family: var(--font-family-georgia);
            margin: 0 0 2px;
            color: rgb(33, 37, 41);
          }

          &__date {
            font-family: var(--font-family-cantarell);
            color: #8e9198;
            text-transform: uppercase;
            font-size: 0.75em;
            display: none;
          }

          &__tags {
            margin: 7px 0px 0;
            line-height: .7em;

            &__tag {
              font-family: var(--font-family-cantarell);
              text-transform: uppercase;
              font-size: 0.6em;
              display: inline-flex;
              margin: 0 5px 5px 0;
              color: white;
              padding: 3px 5px;
              border-radius: 8px;
              background-color: $fourth-color-keppel;

              &.tag--free-note {
                background-color: #202020;
                border: 1px solid #202020;
                color: white
              }
            }
          }
        }

        &__img ::ng-deep {
          .img-lazy-wrapper {
            margin: 0 0 0 10px;
            height: 92px;
            width: 92px;
          }
        }
      }
    }
  }

  .load-more-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .load-more-button {
      font-family: var(--font-family-cantarell);
      color: white;
      text-transform: uppercase;
      font-size: .9em;
      padding: 10px 30px;
      min-width: 550px;
      border: 0 none;
      background-color: $fourth-color-keppel;
      @include radius(10px);
      @include transition(all .3s ease-in-out);

      @include max-phone-width() {
        min-width: 100%;
        flex: 1;
      }

      &:hover {
        color: white;
        background-color: grey;
      }

      &:focus {
        outline: none;
      }

      &.loading {
        position: relative;
        text-indent: -9999px;

        &:after {
          position: absolute;
          content: '';
          border: 2px solid #fff;
          border-color: #fff #fff transparent transparent;
          width: 24px;
          height: 24px;
          top: 25%;
          left: calc(50% - 12px);
          @include animation(button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite);
          @include radius(50%);
          @include transform(translate(-25%, -25%));
        }

        &:disabled {
          background-color: grey;
        }
      }
    }
  }

  .become-a-creator {
    display: flex;
    flex: 100%;
    align-items: center;
    flex-direction: column;
    background-color: #202020; // #050b42;
    padding: 10px;
    margin: 0 0 40px;
    font-family: var(--font-family-georgia);
    @include radius(10px);

    .info {
      // color: white;
      // font-size: 1.4em;
      // line-height: 1.3em;

      color: #c7c7c7;
      text-transform: uppercase;
      font-size: 0.8em;
      font-family: var(--font-family-cantarell);
      line-height: 1.3em;
    }

    button {
      display: inline-flex;
      border: 1px solid white;
      padding: 5px 10px;
      margin: 15px 0 0;
      color: white;
      background-color: transparent;
      justify-content: center;
      width: 130px;

      @include radius(5px);
      @include transition(all .3s ease-in-out);

      font-family: var(--font-family-cantarell);
      text-transform: uppercase;
      font-size: .9em;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }

  .categories-section {
    margin: 0 0 20px;
  }

  .column {
    &--right {
      .lp-headline-main {
        margin: 0 0 20px 10px;
      }
    }
  }

  .create-note-wrapper {
    display: flex;
    flex: 100%;

    @include max-phone-width() {
      margin: 0 0 -20px;
    }
  }

  .stream-news-container {
    display: flex;
    font-family: var(--font-family-georgia);
    flex-direction: column;
    margin: 0 0 40px;
    @include radius(15px);
    overflow: hidden;

    .main-thumbnail-info-holder {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 10px;

      .thumbnail {
        float: left;
        display: flex;
        width: 210px;
        height: 210px;
        margin: 0 20px 20px 0;

        @include max-phone-width() {
          float: none;
          display: flex;
          width: 100%;
          min-height: 90vw;
          margin: 0 0 10px;
        }
      }

      .stream-description {
        position: relative;

        .title {
          margin: 0;
          padding: 0;
          display: none;

          .stream-link {
            color: black;
            text-decoration: none;
            font-weight: 600;
            cursor: pointer;

            &:hover {
              color: $fourth-color-keppel;
            }
          }

          .time {
            color: grey;
            margin: 0 20px;
            font-size: .85em;

            @include max-phone-width() {
              display: none;
            }
          }

          @include max-phone-width() {
            display: flex !important;
            flex: 1 !important;
            align-items: center !important;
            font-size: 0.85em !important;
          }
        }

        .description {
          margin: 0 0 70px;
          padding: 0;
          line-height: 1.4em;

          @include max-phone-width() {
            margin: 10px 0 !important;
            font-size: .9em !important;
            flex: 100% !important;
          }
        }

        &:after {
          content: '';
          clear: both;
        }

        .btn-stream-get-more {
          position: absolute;
          bottom: 10px;
          right: 5px;
          font-size: .65em;
          text-decoration: none;

          @include max-phone-width() {
            display: none !important;
          }
        }

        @include max-phone-width() {
          display: flex !important;
          flex-wrap: wrap !important;
          flex: 1 !important;

          img-lazy-loading ::ng-deep {
            width: 30px !important;
            height: 30px !important;
            min-height: 30px !important;
            margin: 0 10px 0 0 !important;
            overflow: hidden;
            @include radius(50%);
          }
        }
      }

      .stream-title-link {
        margin: 0 0 5px;
        font-size: 1.1em;
        font-weight: 600;
        font-family: var(--font-family-palatino);
        flex: 0 100%;
        cursor: pointer;
        color: #212529;
        text-decoration: none;

        &:hover {
          color: #212529c7;
        }

        @include max-phone-width() {
          display: none !important;
        }
      }
    }

    .steam-notes-wrapper {
      display: flex;
      margin: 0;
      flex-direction: column;
      border-top: 1px solid #8080802b;
      padding: 0 15px 10px;
      background-color: #fff;
      -webkit-border-bottom-right-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-bottomright: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;

      .note-wrapper {
        display: flex;
        margin: 20px 0;

        .note-img {
          display: flex;
          margin: 0 25px 0 0;
          width: 120px;
          height: 120px;
          max-width: 120px;

          @include max-phone-width() {
            margin: 0 15px 0 0;
            width: 60px !important;
            height: 60px !important;
            max-width: 60px !important;
          }
        }

        .note-details {
          flex: 1;

          .tags {
            margin: 3px 0px;
            line-height: .7em;

            .tag {
              font-family: var(--font-family-cantarell);
              text-transform: uppercase;
              font-size: 0.6em;
              display: inline-flex;
              margin: 0 5px 5px 0;
              color: white;
              padding: 3px 5px;
              border-radius: 8px;
              background-color: $fourth-color-keppel;

              &.tag--free-note {
                background-color: #202020;
                border: 1px solid #202020;
                color: white
              }
            }
          }

          .note-title {
            margin: 0 0 5px;
            font-size: 1.1em;
            cursor: pointer;
            color: #212529;
            text-decoration: none;

            &:hover {
              color: $fourth-color-keppel;
              border-bottom: 1px solid grey;
            }

            @include max-phone-width() {
              font-size: 1em;
            }
          }

          .note-description {
            font-size: .9em;
            padding: 0;
            margin: 3px 0 8px;
            color: #777272;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }

          .other-details {
            display: flex;
            align-items: center;
            margin: 12px 0 0;

            @include max-phone-width() {
              flex-direction: column;
              align-items: start;
            }

            .by-user {
              font-family: var(--font-family-palatino);
              display: flex;
              font-size: .8em;
              color: #3c3c3c;
              margin: 0 15px 0 0;
              align-items: end;
              text-decoration: none;

              &:hover {
                color: #39aea9;
              }

              .avatar {
                width: 20px;
                height: 20px;
                margin: 0 6px 0 0;
                @include radius(50%);
              }
            }

            .date {
              font-size: .7em;
              color: black;
              margin: 0 0 2px;
              display: none;
            }
          }
        }
      }

      @include max-phone-width() {
        margin: 0 !important;
        padding: 0 10px !important;
      }
    }

    &.grid-type {
      &--1 {
        .main-thumbnail-info-holder {
          .stream-description {
            .title {
              margin: 0;
              font-size: .85em;
              line-height: 1em;
              color: grey;
            }

            .description {
              margin: 0 0 70px;
            }
          }
        }
      }

      &--2 {
        .main-thumbnail-info-holder {
          .stream-title-link {
            flex: 100%;
          }

          .stream-description {
            .title {
              margin: 0;
              font-size: .85em;
              line-height: 1em;
              color: grey;
            }

            .description {
              line-height: 1.4em;
              margin: 0 0 55px;
            }
          }
        }

        .note-wrapper {
          flex-direction: row-reverse;

          .note-img {
            margin: 0 0 0 25px !important;
          }
        }
      }

      &--3 {
        .main-thumbnail-info-holder {
          .stream-description {
            .title {
              margin: 0;
              font-size: .85em;
              line-height: 1em;
              color: grey;
            }

            .description {
              margin: 0 0 70px;
            }
          }
        }

        .steam-notes-wrapper {
          flex-direction: row;
          align-items: flex-start;

          @include max-w-1024() {
            flex-direction: column;
          }

          .note-wrapper {
            flex-direction: column;
            flex: 1;
            margin: 10px 40px;

            @include max-w-1024() {
              margin: 10px 0;
              display: flex;
              flex-direction: row;
            }

            &:first-child, &:last-child {
              margin: 10px 0;
            }

            .note-img {
              display: grid;
              width: 100%;
              max-width: 100%;
              height: 270px;
              margin: 0 0 30px;

              @include max-w-1024() {
                margin: 0 15px 0 0;
                width: 120px;
                height: 120px;
                max-width: 120px;
              }
            }

            .other-details {
              flex-direction: column;
              align-items: start !important;

              .by-user {
                margin: 0 0 5px !important;
              }
            }
          }

          &.single-note-display {
            .note-wrapper {
              flex-direction: row-reverse;

              .note-img {
                margin: 0 0 0 25px !important;
                width: 120px;
                height: 120px;
                max-width: 120px;

                @include max-phone-width() {
                  margin: 0 0 0 15px !important;
                  width: 100px;
                  height: 100px;
                  max-width: 100px;
                }
              }
            }
          }

          &.double--note {
            .note-img {
              height: 455px !important;

              @include max-w-1024() {
                height: 125px !important;
              }

              @include max-phone-width() {
                height: 60px !important;
              }
            }

            .note-wrapper {
              &:first-child {
                margin: 10px 10px 10px 0;

                @include max-w-1024() {
                  margin: 10px 0;
                }
              }

              &:last-child {
                margin: 10px 0 10px 10px;

                @include max-w-1024() {
                  margin: 10px 0;
                }
              }
            }
          }
        }
      }

      &--4 {
        flex-direction: initial;
        flex-wrap: wrap;

        @include max-phone-width() {
          flex-direction: column;
        }

        .main-thumbnail-info-holder {
          flex: 0 330px;
          padding: 15px;

          @include max-phone-width() {
            flex: 1;
          }

          .stream-description {
            height: 100%;

            @include max-phone-width() {
              height: auto;
            }

            .thumbnail {
              width: 300px;
              height: 300px;
              margin: 0 0 15px;

              @include max-phone-width() {
                width: 100%;
                height: inherit;
              }
            }

            .btn-stream-get-more {
              display: none;
            }
          }

          .stream-title-link {
            flex: 0;
          }
        }

        .steam-notes-wrapper {
          flex: 1;
          border-top: 0 none;
          padding: 30px 15px 15px 20px;

          @include max-phone-width() {
            padding: 10px;
          }

          .note-wrapper {
            border-bottom: 1px solid #80808021;
            padding: 0 0 20px;

            @include max-phone-width() {
              padding: 0 0 10px;
            }

            &:last-child {
              border-bottom: 0 none;
            }

            .note-img {
              width: 100px;
              height: 100px;
              max-width: 100px;
            }
          }
        }
      }
    }

    .content-loader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 40px auto;

      &__spinner {
        position: relative;
        background: #f3f3f3;
        @include radius(10px);
        width: 50px;
        height: 50px;

        &:after {
          position: absolute;
          content: '';
          border: 2px solid #444444;
          border-color: #44444478 #44444478 transparent transparent;
          width: 25px;
          height: 25px;
          top: 25%;
          left: 25%;
          @include animation(button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite);
          @include radius(50%);
          @include transform(translate(-25%, -25%));
        }
      }

      &__label {
        font-family: var(--font-family-cantarell);
        text-transform: uppercase;
        font-size: 0.7em;
        margin: 10px 0 0;
      }
    }
  }

  .populat-categories {
    display: flex;
    flex: 1;
    margin: 0 0 25px;

    &__list {
      margin: 0;
      padding: 15px 10px;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      justify-content: space-between;
      background-color: white;
      @include radius(10px);

      &__category {
        &__label {
          flex: 1;
          padding: 5px 8px;
          font-family: var(--font-family-cantarell);
          text-decoration: none;
          text-transform: uppercase;
          font-size: .7em;
          color: #000;
          cursor: pointer;
          @include radius(7px);

          &:hover {
            color: #8e9198;
          }
        }
      }
    }

    @include max-phone-width() {
      display: none;
    }
  }
}

h2 {
  font-size: 28px;
  font-weight: normal;
}

.notd-recent-stream {
  display: flex;
  align-items: stretch;

  >.item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .head {
    }
  }

  div {
    position:relative;
  }
}

.no-mobile {
  display: block;

  @include max-phone-width() {
    display: none;
  }
}

.banner-top {
  position: relative;
  width: 100%;
  min-height: 160px;
  overflow: hidden;
  background-image: url("/assets/bg-1.jpg");
  background-size: cover;

  @include max-small-screen-width() {
    background-image: url("/assets/bg-1-medium.jpg");
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    min-height: 80px;
    background-image: url("/assets/bg-1-small.jpg");
  }

  @media only screen and (min-width: 1441px) {
      min-height: 230px;
  }

  .btn-close {
    position: absolute;
    z-index: 3;
    left: 40px;
    top: 15px;
    background: #5fa7a5;
    border-radius: 50%;
    border: 1px solid #49a9a6;
    width: 25px;
    height: 25px;
    font-size: 0.8rem;
    color: #f5f5f5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    @include transition(all 0.5s);

    &:hover {
      transition: all 0.5s;
      background: #2d6f6e;
    }

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      left: 4px;
      top: 5px;
      width: 20px;
      height: 20px;
      font-size: 10px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      left: 15px;
    }

  }

  .introduce-section{
    //background: rgba(245, 245, 245, 0.6);
    //background: rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.8);

    padding: 0 100px;
    margin-top: 30px;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 0 20px;
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      padding: 0;
    }

    .item {
      margin: 15px 0;
      .ico{
        text-align: center;
        margin-bottom: 20px;
        img{

          width: 50px;
          display: inline-block;

        }
      }
    }

  }

  .content {
    font-weight: 600;

    //position: absolute;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 0 60px;
    padding-top: 30px;
    text-align: center;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      padding: 10px;
      padding-top: 20px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      padding: 0 20px;
      padding-top: 30px;
    }

    @media only screen and (min-width: 1025px) and (max-width: 1199px) {
      padding: 0 20px;
      padding-top: 30px;
    }

    h3{

      color: white;
      font-size: 2.8em;
      font-weight: 600;
      margin-top: -9px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 1em;
      }

    }

    p {
      color: white;
      margin-top: 20px;
      font-size: 22px;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        margin-top: 0;
        font-size: 12px;
        margin-bottom: 10px;
      }
    }

    .btn {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.55);
      color: #557b83;
      font-size: 1.5em;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: white;
        transition: all 0.2s ease-in-out;
      }

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        font-size: 0.7em;
      }
    }
  }

  h3 {
    @include max-phone-width() {
      font-size: 16px;
      margin: 8px 0 15px;
    }
  }

  .btn-close {
    left: 20px;

    @include max-phone-width() {
      width: 25px;
      height: 25px;
      left: 48px;
    }

    i {
      @include max-phone-width() {
        left: 0;
        top: 0;
      }
    }
  }

  @include max-phone-width() {
    min-height: 120px;
    border-bottom: 4px solid black;
  }
}

.stream-section {
  // background: #f5f5f5;
  padding: 20px;
  display: none;

  @include max-phone-width() {
    display: none; //flex
    overflow: hidden;
    background: linear-gradient(#5e8990, #d9e7c3);
    width: 100%;
  }

  &.container {
    @include max-tablet-width() {
      margin-top: 0;
    }
  }

  .title {
    text-align: center;
    margin: 10px 0 30px;
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      margin-bottom: 20px;
      margin-top: 0;
    }

  }

  .form-box {
    .form-control {
      border: none;
      background: none;
      width: 80%;
      display: inline-block;
      border-bottom: 1px solid #c1c1c1;
      border-radius: 0;
    }

    .btn {
      border: none;
      background: none;
      cursor: pointer;
    }


    .slide {
      padding: 0 50px;
      @media only screen and (min-width: 320px) and (max-width: 767px) {
          padding: 0;
      }
    }

  }

  .side-nav {
    background: none;

    &.hide {
      margin-right: 0;
    }

    .mobile-toggle-btn {
      left: -40px;

      &.swipe {
        left: 0;
      }
    }
  }

  h2 {
    margin: 0 0 18px;

    &.header-cat-selector {
      display: flex;
      align-items: center;

      a {
        color: rgba(0, 0, 0, 0.2);
        font-size: 26px;
        cursor: pointer;

        @include max-phone-width() {
          font-size: .9rem !important;
          width: 140px;
          line-height: 38px;
          height: 38px;
          @include radius(5px);
          background: white;
          color: $secondary-color-carmine;
          @include transition(all .3s);
          @include box-shadow(rgba(0, 0, 0, 0.15) 0px 1px 3px 2px !important);

          &:hover {
            color: white;
            background: $secondary-color-carmine;
          }

          &.selected {
            color: white !important;
            background: $secondary-color-carmine;
          }

          &:nth-child(2) {
            margin-right: 15px;
          }
        }

        &.selected {
          color: $primary-color-black;
          font-size: 30px;

          @include max-phone-width() {
            font-size: 18px;
          }
        }
      }

      .menu-filter {
        display: none;
        width: 36px ;
        height: 36px;
        margin-right: 15px;
        background: transparent url("/assets/svg/menu-filter.svg") no-repeat;
        background-size: cover;
        @include box-shadow(none !important);

        &:hover {
          background: transparent url("/assets/svg/menu-filter.svg") no-repeat;
        }

        @include max-phone-width() {
          display: block;
        }
      }

      .separator {
        background-color: rgba(0, 0, 0, 0.5);
        width: 1px;
        height: 40px;
        font-size: 0;
        margin: 0 16px;
        @include transform(rotate(30deg));

        @include max-phone-width() {
          height: 30px;
          display: none;
        }
      }

      @include max-phone-width() {
        @include transition(all .5s ease-in-out);
      }
    }

    @include max-phone-width() {
      font-size: 26px;
      text-align: center;
      margin: 0 auto 18px;
    }
  }

  .search-bar {
    display: flex;
    align-items: center;

    @include max-phone-width() {
      flex-wrap: wrap;
    }

    search-content {
      flex: 1 0;

      @include max-phone-width() {
        @include box-shadow(rgba(0, 0, 0, 0.15) 0px 1px 3px 2px);
        @include radius(5px);
        overflow: hidden;

        input {
          font-size: 1.2rem !important;
        }
      }
    }

    button {
      flex: 0 0 auto;
      background-color: $secondary-color-carmine;
      height: 40px;
      margin-left: 18px;
      border: 0 none;

      @include max-phone-width() {
        margin: 17px 0 0;
        font-weight: 300;
        flex: 1 0 auto;
      }
    }
  }

  .streams-list {
    padding: 17px 0 20px;
    display: flex;
    justify-content: unset;

    @include max-phone-width() {
      position: relative;
      padding: 17px 0 0;
      height: 390px;
    }

    .streams-wrapper {
      flex: 1 1 100%;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;

      @include max-phone-width() {
        display: flex;
        flex-wrap: nowrap;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
        overflow-x: scroll;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: -10px;
        padding: 20px 0 0;

        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        scrollbar-width: none;

        &:not(.is-draggable) {
          @include transition(all .15s ease-in-out);
        }

        &::-webkit-scrollbar {
          display: none !important;
          width: 0 !important;
          height: 0 !important;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          display: none !important;
        }
      }

      &.is-draggable {
        overscroll-behavior: contain;
      }

      // adding new design to lp
      .stream-container {
        display: flex;
        flex: 0 100%;
        background: #fff;
        padding: 10px;
        margin: 0 0 10px;
        flex-wrap: wrap;
        @include box-shadow(rgba(0,0,0,.05) 0 0 20px 0);

        @include max-phone-width() {
          display: none;
        }

        &.odd {
          .thumbnail {
            order: 1;
            //margin: 0 0 0 10px;
          }

          .notes {
            order: 0;

            .news-wrapper {
              margin: 0 10px 0 0;
            }
          }

          .other-notes-on-stream {
            order: 2;
          }
        }

        .stream-info-wrapper {
          padding: 0 0 10px;
          text-decoration: none;
          cursor: pointer;
          width: 100%;

          &:hover {
            h3, .description {
              color: $third-color-blue;
            }
          }

          h3 {
            margin: 0;
            font-size: 1.3em;
            font-weight: bold;
          }

          .description {
            color: #666d7a;
          }
        }

        .thumbnail {
          flex: 0 40%;
          //margin: 0 10px 0 0;
          background-size: cover;
          background-position: 50% 50%;
          height: calc(100vw/4);
          min-height: 250px;
          position: relative;
          @include transition(all .3s ease-in-out);
          cursor: pointer;

          &:hover {
            opacity: .9;
          }

          @include only-ipad-vertical() {
            flex: 0 30%;
            min-height: 250px;
          }

          &.default {
            background-size: 180px;
            background-repeat: no-repeat;
            background-position: 50% 15%;
            background-color: #e6e6e6;
          }

          .details {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            color: white;
            padding: 20px 10px 10px;
            cursor: pointer;
            //background-color: #f5f5f5f0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, .5) 100%);

            &:hover {
              .description {
                text-decoration: underline;
              }
            }

            h2 {
              font-size: .9em;
              font-weight: 600;
              padding: 0 0 3px;
              margin: 0;
            }

            .description {
              font-size: .8em;
              line-height: 1.7em;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          .more-details {
            position: absolute;
            top: 10px;
            right: 10px;
            display: flex;
            align-items: center;
            background: $third-color-blue;
            padding: 0 5px;
            color: white;
            font-size: 0.8em;
            @include radius(15px);

            .subscribers {
              padding-left: 25px;
              background: url('/../../assets/svg/users-group.svg') 3px 50% no-repeat;
              background-size: 18px;
            }

            .notes {
              padding-left: 25px;
              background: url('/../../assets/svg/article.svg') 3px 50% no-repeat;
              background-size: 18px;
            }
          }
        }

        .notes {
          flex: 0 60%;
          display: flex;
          justify-content: space-between;

          @include only-ipad-vertical() {
            flex: 0 70%;
          }

          .news-wrapper {
            flex: 3;
            background-color: #f5f5f5;
            position: relative;
            @include transition(all .3s);
            margin: 0 0 0 10px;

            &:hover {
              background-color: #F0F0F0;
            }

            &.one {
              flex: 1;
            }

            &.two {
              flex: 2;
            }

            &.single-note {
              .thumb {
                display: none;
              }

              .title {
                font-size: 1em;
                font-weight: bold;
                padding: 10px 10px 5px;
              }

              .note-content-text {
                display: block;
              }
            }

            .thumb {
              //height: 135px;
              height: calc(100vw/8);
              margin: 0 0 10px;
              background-size: contain;
              background-position: 50% 50%;
              background-repeat: no-repeat;

              &.default {
                background-size: 105px;
                background-color: #d3d3d370;
              }

              &.square {
                background-size: cover;
              }
            }

            .title {
              font-size: 0.8em;
              line-height: 1.3em;
              text-align: left;
              padding: 0;
              margin: 5px;
              color: $primary-color-black;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              word-break: break-word;

              @include only-ipad-vertical() {
                font-size: 0.7em;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
                padding: 5px 5px 0;
              }

              &:hover {
                text-decoration: none;
                color: $third-color-blue;
              }
            }

            .note-content-text {
              display: none;
              padding: 10px;

              p {
                display: -webkit-box;
                font-size: .9em;
                color: grey;
                margin: 0;
                padding: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                word-break: break-word;
              }

              img {
                float: left;
                height: 140px;
                margin: 5px 10px 10px 0;
              }
            }

            .other-details {
              position: absolute;
              bottom: 5px;
              left: 5px;
              right: 5px;
              text-align: right;

              .created {
                font-size: .7em;
                color: $third-color-blue;
                display: inline-block;
              }
            }
          }
        }

        .other-notes-on-stream {
          flex: 1 100%;
          padding: 10px 0 0;

          h2 {
            display: flex;
            margin: 0 0 10px;
            padding: 0;
            text-transform: uppercase;
            color: #505862;
            font-size: .85em;
            font-weight: bold;

            &:after {
              background-color: #d3d3d352;
              content: '';
              flex: 1;
              margin: 0 0 0 10px;
            }
          }

          .note-listed {
            margin: 0 0 10px;

            .note-title-and-content {
              text-decoration: none;

              &:hover {
                h3, .small-info {
                  color: $fourth-color-keppel;
                }
              }

              h3 {
                color: #22262a;
                font-weight: 500;
                font-size: .9em;
                margin: 0;
              }

              .small-info {
                color: #666d7a;
                font-size: .8em;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              @include only-ipad-vertical() {
                font-size: .8em;
              }
            }

            .details {
              display: flex;
              margin: 5px 0 0;

              span, a {
                text-transform: uppercase;
                color: #98a3b3;
                font-size: .65em;
                margin: 0 20px 0 0;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .load-more {
      flex: 1 1 100%;
      padding: 5px 0 0;

      @include max-phone-width() {
        display: none;
      }

      .btn {
        background-color: $secondary-color-carmine;
        height: 40px;
        width: 145px;
        font-size: 16px;
        margin: 0 auto;
        border: 0 none;

        &:hover {
          background-color: $fourth-color-keppel;
        }
      }
    }

    .arrow {
      display: none;
      position: absolute;
      top: 46%;
      cursor: pointer;
      z-index: 4;
      background: $main-background-color;
      height: 40px;
      width: 40px;
      @include radius(50%);
      @include transform(translate(0, -50%));
      @include box-shadow(rgba(0, 0, 0, 0.15) 0 1px 6px 0);

      img {
        height: 30px;
      }

      @include max-phone-width() {
        display: block;
      }

      &.left {
        left: -10px;

        img {
          margin: 6px 0 0 4px;
        }
      }

      &.right {
        right: -10px;

        img {
          margin: 6px 0 0 6px;
        }
      }
    }
  }

  .slider-info {
    display: none;
    text-align: center;

    @include max-phone-width() {
      display: block;
    }

    .circles {
      .circle {
        display: inline-block;
        font-size: 0;
        margin: 0 3px;
        width: 8px;
        height: 8px;
        background: $third-color-blue;
        @include transition(all .3s);
        @include radius(50%);

        &.selected {
          background: $fourth-color-keppel;
        }
      }
    }

    .info {
      display: block;
      padding: 0 0 4px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.sign-up-section {
  position: relative;
  overflow: hidden;

  .content {
    text-align: center;
    margin: 50px auto 30px;
    color: #fff;

    @include max-phone-width() {
      width: auto;
      margin: 22px 20px 30px;
    }

      h2 {
      font-size: 28px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px;

      @include max-phone-width() {
        flex-direction: column;
      }

      .logo {
        margin: 0 0 0 15px;

        @include max-phone-width() {
          margin: 0;
        }
      }
    }

    .buttons {
      margin: 50px auto 0;
      width: 560px;

      @include max-phone-width() {
        margin: 25px auto 0;
        width: auto;
      }

      button {
        display: block;
        font-size: 16px;
        height: 45px;
        width: 100%;
        margin: 0 0 10px;
        border: 0 none;
        color: #fff;
        @include radius(6px);
        background-color: $primary-color-black;

        &.sign-up {
          background-color: $secondary-color-carmine;
        }

        &:hover {
          background-color: $third-color-blue;
        }
      }
    }

  }

  .bg {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: -1;
    @include transform(translate(0, -50%));

    @include max-phone-width() {
      height: 100%;
      width: auto;
      top: 0;
      left: 50%;
      @include transform(translate(-50%, 0));
    }
  }

  &.logged-in {
    margin-bottom: -15px;

    .content {
      margin: 50px auto;

      .buttons {
        display: none;
      }
    }
  }
}

.news-section {
  .content {
    padding: 50px 100px;

    .grid {
      text-align: left;
      margin: 0 auto;
    }

    h2 {
      font-size: 28px;
      font-weight: 700;
    }

    p {
      padding: 0;
      font-size: 16px;
    }

    .form-signup {
      margin: 65px auto 0;
      width: 660px;

      h2, .sign-in {
        font-weight: 700;
      }

      h2 {
        font-size: 24px;
      }

      .sign-in {
        font-size: 20px;
        cursor: pointer;

        &:hover {
          color: $secondary-color-carmine;
        }
      }

      .form-box-signin {
        input {
          font-size: 14px;
          height: 45px;
        }

        button {
          font-size: 16px;
          height: 45px;
        }
      }
    }
  }
}

.prelaoder {
  flex: 1 0 100%;
  text-align: center;
  padding: 120px 0;
}

