@import '../../scss/variables.scss';
$stream-detail-icon-width: 110px;

app-stream-details {
  display: flex;
  flex-direction: column;
  height: 100%;

  .container {
    flex: 1 0 auto;
  }
  
  .content-wrapper {
    @include max-tablet-width() {
      overflow: hidden;
    }
  }

  .stream-wrapper {
    flex-direction: column;

    &.wrapper--centered {
      width: 960px;

      @include max-w-1024() {
        width: auto;
      }

      @include from-768-to-1024() {
        width: auto;
        margin: 75px 25px 10px !important;

        .stream-notes-wrapper {
          margin: 10px 0;
        }
      }
    }

    .stream-details {
      position: relative;
      display: flex;
      flex: 0 1;
      flex-direction: column;
      background-color: white;
      padding: 10px;
      margin: 0 0 50px;
      font-family: var(--font-family-georgia);
      @include radius(15px);

      &__stream-options {
        display: flex;
        align-items: center;

        @include max-phone-width() {
          flex-direction: column;
        }

        &__author-info {
          display: flex;
          align-items: center;
          font-size: 1em;
          margin: 0 auto 0 0;

          @include max-phone-width() {
            margin: 20px 0;
          }
  
          &__avatar {
            width: 40px;
            height: 40px;
            margin: 0 10px 0 0;
            cursor: pointer;
            overflow: hidden;
            @include radius(50%);
  
            &:hover {
              opacity: .7;
            }
          }
  
          &__details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-family: var(--font-family-cantarell);
            text-transform: uppercase;
            font-size: .7em;
  
            &__label {
              color: #8e9198;
              margin: 0 0 4px;
            }
  
            &__name {
              text-decoration: none;
              color: #212529;
              font-weight: 600;
  
              &:hover {
                color: black;
              }
            }
          }
        }

        &__actions {
          display: flex;
          align-items: center;
          font-size: 1.6em;

          button {
            margin: 10px 5px 0 !important;
          }

          @include max-phone-width() {
            justify-content: center;
            flex-wrap: wrap;
          }

          .subscribe--button {
            padding: 8px 10px;
            margin: 0 10px 0 20px;
            font-family: var(--font-family-cantarell);
            text-transform: uppercase;
            font-size: .5em;
            border: 0 none;
            background-color: #c00;
            color: #fff;
            @include radius(10px);
    
            &:hover {
              background-color: #b30606;
            }
          }

          .button--icon {
            position: relative;
            padding-left: 35px;

            svg {
              position: absolute;
              width: 20px;
              height: 20px;
              left: 10px;
              top: 50%;
              @include transform(translate(0, -50%));

              path {
                fill: #fff;
                stroke-width: 1;
                stroke: none;
              }
            }
          }
        }
      }

      &__thumb-desc {
        &:after {
          content: '';
          clear: both;
          display: table;
        }
      }
  
      
      &__thumbnail {
        height: 400px;
        width: 400px;
        min-width: 400px;
        display: flex;
        float: left;
        margin: 0 20px 20px 0;

        @include max-phone-width() {
          display: block;
          height: 90vw;
          width: 100%;
          min-width: auto;
          float: none;
        }
      }

      &__title {
        margin: 20px 0 10px;
        flex-wrap: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: var(--font-family-georgia);

        @include max-phone-width() {
          flex-direction: column;
        }

        &__stats-holder {
          display: flex;
          flex-direction: column;
          margin: 0 10px 0 0;

          @include max-phone-width() {
            margin: 10px 0;
          }

          &__label {
            font-size: 1.25em;
            font-weight: 500;
            margin: 0;
          }

          &__subscribers {
            color: #8e9198;
            font-size: .5em;

            @include max-phone-width() {
              text-align: center;
            }
          }
        }

        &__actions {
          display: flex;
          align-items: center;
          font-size: .7em;

          @include max-phone-width() {
            margin: 10px auto;
          }

          &__follow-wrapper {
            display: flex;
            align-items: baseline;

            &__bell-icon {
              position: relative;
              background: none !important;
              padding: 0 !important;

              &__bell {
                height: 26px;
                width: 26px;
              }

              &__checked {
                position: absolute;
                width: 18px;
                border-radius: 16px;
                background: #f7f7f7;
                height: 18px;
                left: 13px;
                top: 2px;
              }

              &:hover {
                svg {
                  fill: #8e9198;
                }
              }
            }
  
            label {
              ffont-family: var(--font-family-cantarell);
              text-transform: uppercase;
              font-size: 0.7em;
              margin: 0;
            }
  
            button {
              margin: 0 7px;
              padding: 0 8px;
              height: 36px;
              background: #39aea9;
              color: white;
              border: 0 none;
              font-family: var(--font-family-cantarell);
              text-transform: uppercase;
              font-size: .7em;
              @include radius(8px);
  
              &:hover {
                background-color: #339e9a;
              }

              &.button--followed {
                &:after {
                  content: 'Following'
                }

                &:hover {
                  background-color: black;

                  &:after {
                    content: 'Unfollow'
                  }
                }
              }
            }
          }
  
          .share--button {
            margin: 0 0 0 10px;
            padding: 0 8px;
            height: 36px;
            font-family: var(--font-family-cantarell);
            text-transform: uppercase;
            font-size: .7em;
            background-color: #d2dfff;
            border: 0 none;
            color: #4e7ef8;
            @include radius(8px);
  
            &:hover {
              background-color: #becff9;
            }
  
            svg {
              height: 18px;
              width: 18px;
              margin: 0 3px 0 0;
  
              fill: #4e7ef8;
            }
  
            social-sharing {
              display: none;
            }
          }

          .label-info {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 10px;
            padding: 0 8px;
            height: 36px;
            font-family: var(--font-family-cantarell);
            text-transform: uppercase;
            font-size: .7em;
            background-color: #fff;
            border: 0 none;
            color: #4e7ef8;
            cursor: pointer;
            @include radius(8px);

            svg {
              margin: 0 6px 0 0;
            }

            &.type {
              &--alert {
                border: 1px solid red;
                color: red;

                svg {
                  fill: red;
                }
              }
            }
          }

          @include max-phone-width() {
            .tooltip {
              display: none;
            }
          }
        }
      }

      &__description {
        color: #757575;
        line-height: 1.6em;
        margin: 0 0 20px;
      }

      &__other {
        text-align: center;

        &__loader {
          font-size: 0.9em;
          margin: 10px auto;
          text-align: center;
        }

        .login-or-subscribe-info {
          border-top: 1px solid #d3d3d37a;
          padding: 10px 0 0;
          margin: 20px 0 0;
          
          .signup-or-login-button {
            color: #fff;
            font-family: var(--font-family-cantarell);
            text-transform: uppercase;
            font-size: .7em;
            border: 0 none;
            padding: 3px;
            margin: 0 auto;
            background-color: #c00;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            @include transition(all .25s);
            @include radius(10px);
        
            &:hover {
              background-color: #339e9a;
            }
        
            &.min-price-label {
              &.button-spinner {
                padding-left: 30px;
        
                &:after {
                  left: 5px;
                  top: 9px;
                  right: initial;
                }
              }
            }
        
            .subs-min-price {
              bottom: 2px;
              background: white;
              font-weight: bold;
              line-height: 2.4em;
              padding: 0 5px;
              margin: 0 0 0 10px;
              color: $primary-color-black;
              @include radius(8px);

              @include max-phone-width() {
                top: 32px;
                left: 2px;
                font-size: .9em;
              }
            }
          }
        }

        .exp-subscription-messages {
          padding: 10px 0 0;
          margin: 10px 0 0;
          font-family: var(--font-family-cantarell);
          text-transform: uppercase;
          font-size: .9em;
          text-align: center;
          border-top: 1px solid #d3d3d37a;

          .message {
            cursor: pointer;
            color: #2f8e8a;
            font-weight: 600;

            &:hover {
              color: #212529;
            }
          }
        }

        .close-sub-button {
          font-size: .8em;
          margin: 0 auto;
          display: flex;
          min-width: 400px;
          justify-content: center;
        }
      }

      .free-users-info {
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #39aea9;
        line-height: 1.25em;
        padding: 10px 30px;
        bottom: -50px;
        width: 180px;
        font-weight: 600;
        text-align: center;
        right: -73px;
        transform: rotate(-45deg);
        color: white;
        height: 130px;

        .sub-price {
          font-family: var(--font-family-cantarell);
          text-transform: uppercase;
          font-size: 1em;
          display: none;
          font-weight: 600;
          padding: 0 0 4px;
        }

        &.extend-price {
          flex-direction: column;
          padding: 10px 50px;
          bottom: -37px;
          width: 230px;
          font-weight: 400;
          right: -87px;
          font-size: .9em;

          .sub-price {
            font-size: 1.1em;
            display: flex;
            justify-content: center;
          }
        }
      }

      &__thumb-desc {
        display: flex;

        @include max-phone-width() {
          flex-direction: column;
        }
      }

      &__add-thumb-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 10px 10px 0;
        padding: 10px;
        width: 250px;
        min-width: 250px;
        background-color: #d3d3d342;
        cursor: pointer;
    
        @include radius(15px);

        &:hover {
          background-color: #d3d3d37a;
        }

        @include max-phone-width() {
          width: 100%;
          margin: 0 0 20px;
        }
    
        svg {
          width: 140px;
          margin: 10px 0;

          path {
            fill: #38aea9;
          }
        }
    
        &__title {
          text-align: center;
          margin: 0 0 10px;
        }
      }
    }

    .subscriptions-wrapper {
      display: none;
      flex: 1;
      flex-direction: column;
      background-color: white;
      padding: 10px;
      @include radius(15px);

      &.plans--visible {
        display: flex;
      }
    }

    .stream-notes-wrapper {
      margin: 30px 0;

      @include max-w-1024() {
        margin: 10px 60px;
      }

      @include max-tablet-width() {
        margin: 10px 20px;
      }

      @include max-phone-width() {
        margin: 10px 0;
      }
      
      &__loading-notes {
        display: flex;
        background-color: white;
        font-family: var(--font-family-georgia);
        justify-content: center;
        align-items: center;
        padding: 50px 20px;
        @include radius(15px);
  
        fa-icon {
          margin: 0 10px 0 0;
        }
      }

      note {
        .note-wrapper__content__current__text {
          @include max-phone-width() {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    &__show-more {
      padding: 5px 0 20px;
      text-align: center;

      button {
        font-size: .9em;
      }
    }
  }

  h3.subheader {
    height: 24px;
    font-family: var(--font-family-titillium);
    font-size: 24px;
    color: #5b5151;
    font-weight: 700;
    text-decoration: none solid rgb(91, 81, 81);
    line-height: 24px;
  }

  .expiration-message, .extend-subscription {
    cursor: pointer;
    font-weight: bold;
    padding: 6px 2px;

    &:hover {
      text-decoration: underline;
    }

    span {
      margin-right: 2px;
    }

  }
  .expiration-message{
    color: #ad3a3a;
  }
  .extend-subscription{
    color: #557b83;
  }

  .stream-edition-controls {
    display: flex;
    flex-flow: column;

    button {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      min-height: 35px;
      line-height: 35px;

      &.share-stream-button {
        margin-top: 5px !important;
      }
    }
  }

  p {
    word-break: break-word;
  }

  ngb-rating {
    outline: none;
  }

  .no-mobile {
    display: block;

    @include max-phone-width() {
      display: none;
    }
  }

  .no-desktop {
    display: none;

    @include max-phone-width() {
      display: block;
    }
  }

  .stream-action-button {
    display: none;

    button {
      margin: 5px 7px 5px 0 !important;
    }

    @include max-tablet-width() {
      button, stream-wizard {
        margin: 2px 0 !important;
        flex: 0 49%;
      }

      stream-wizard button {
        margin: 0 !important;
        width: 100%;
      }
    }

    @include max-tablet-width() {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .subscriptions-dropdown {
    margin-bottom: 20px;
  }

  .subscriptions-loader {
    border-top: 1px solid #d3d3d37a;
    font-size: .9em;
    margin: 10px 0 0;
    padding: 20px 0 0;
    width: 100%;

    .ng-fa-icon {
      margin: 5px;
    }
  }

  .search-notes-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 15px;

    search-input {
      flex: 0 400px;

      .notes-search {
        input {
          border: 1px solid #d3d3d391 !important;
          @include radius(5px !important);
        }
      }
    }
  }
}

.stream-details {
  .stream-info {
    flex-direction: column;
  }

  .notd-thumb {
    flex: 0 0 auto;
    margin: 0 10px 10px 0;

    img {
      max-width: 100%;
      width: $stream-detail-icon-width;
    }

    > div {
      > button {
        margin-top: 0;

        &:first-child{
          margin-top: 10px;
        }
      }
    }

    button {
      width: 100%;
      word-break: break-word;
      min-width: 0;

      @media only screen and (min-width: 767px){
        max-width: $stream-detail-icon-width;
      }
    }

    @include max-phone-width() {
      width: 110px;
    }
  }

  .notd-details {
    flex: 1 1 auto;
    background: white;
    padding: 10px;
    @include radius(5px);
    @include box-shadow(rgba(0,0,0,.05) 0 0 20px 0);

    .edit-btn {
      display: none;
      margin: 5px 0 0;

      @include max-phone-width() {
        display: block;
      }
    }

    .desc {
      @include max-phone-width() {
        display: none;
      }
    }

    .rate {
      color: $school-bus-yellow;
      font-size: 18px;
      padding: 0 30px 0 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include max-phone-width() {
        margin-left: 15px;
        flex: 1 1 auto;
        justify-content: flex-start;
      }

      .unrated {
        color: #cccccc;
      }

      .fa-star:before {
        padding-right: 2px;
      }
    }

    .action-buttons {
      margin-bottom: 20px;

      button {
        margin: 3px;
        height: auto;
        font-size: .8em;
        @include transition(all .2s ease-out);

        &:hover {
          background-color: $secondary-color-carmine;
        }
      }

      @include max-phone-width() {
        display: flex;
        flex-flow: column;

        button {
          margin: 3px;
        }
      }
    }

    .buttons {
      button {
        width: 120px;
      }
    }

    .notd-thumb {
      display: none;

      @include max-phone-width() {
        display: block;
        margin: 0 24px 10px 40px;
      }
    }

    &.free-users {
      overflow: hidden;
      position: relative;

      .free-users-info {
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #39aea9;
        line-height: 1.25em;
        padding: 10px 30px;
        bottom: -50px;
        width: 180px;
        font-weight: 600;
        text-align: center;
        right: -73px;
        transform: rotate(-45deg);
        color: white;
        height: 130px;

        .sub-price {
          display: none;
          font-weight: 600;
          font-size: 1.15em;
          padding: 0 0 4px;
        }

        &.extend-price {
          flex-direction: column;
          padding: 10px 50px;
          bottom: -33px;
          width: 230px;
          font-weight: 400;
          right: -83px;

          .sub-price {
            display: flex;
            justify-content: center;
          }
        }
      }

      .login-or-subscribe {
        .signup-or-login-button {
          padding-right: 15px;
        }

        .subs-min-price {
          display: none;
        }
      }
    }

    .free-users-info {
      display: none;
    }
  }

  .share-stream-button {
    padding: 0;
    height: 35px;
    display: inline-flex;
    align-items: baseline;
    justify-content: center;
    background-color: $fourth-color-keppel;
    @include transition(all .2s ease-out);

    &:before {
      content: 'Share';
      margin-right: 10px;
    }

    &:hover {
      background-color: $third-color-blue;
    }

    social-sharing {
      svg {
        width: 23px !important;
        height: 23px !important;

        path {
          fill: #fff !important;
        }
      }
    }
  }

  .stats {
    width: 100%;

    div{
      font-size: 16px;
    }
  }

  form {
    button, input{
      //margin-bottom: 10px;
      //margin-top: 10px;
    }
  }

  stream-plans {
    width: 100%;
  }

  .stream-earnings {
    margin-bottom: 20px;
  }

  .note-loading-wrapper {
    font-size: .9em;
    padding: 50px 0;
    background: #fff;
    margin: 20px 0;
    @include box-shadow(rgba(0,0,0,.05) 0 0 20px 0);

    .ng-fa-icon {
      margin: 5px;
    }
  }
}

.notd-stream-price-list{
  margin-top: 20px;

  .notd-stream-price-list-header{
    padding-top: 20px;

    >div{
      display: inline-block;
    }
  }
}

.notd-stream-subscribers {
  margin-bottom: 20px;

  .stream-owner {
    font-weight: bold;
    margin: 0 15px;
    flex: 1 0 100%;

    .profile-name {
      color: $third-color-blue;
      display: inline-block;
      margin: 0 0 0 15px;

      @include max-phone-width() {
        margin: 0;
      }
    }
  }

  .stats-container {
    align-items: baseline;
    font-weight: bold;
    margin: 0 15px 0;
    flex: 1 0 100%;

    a {
      color: #557b83;
      font-weight: bold;
    }

    .sub-counter {
      font-weight: normal;
      display: inline-block;
      padding: 0 15px;
    }
  }

  .stats-label{
    font-weight: bold;
    max-width: 160px;
  }

  @include max-phone-width() {
    .stats-value{
      text-align: center;
    }
  }

  .info {
    @media only screen and (min-width: 768px) {
      display: flex;
    }

    > div {
      flex-direction: column;
      display: flex;

      > div {
        flex: 1 1 auto;
        font-size: 20px;

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }

        margin-bottom: 5px;
        text-align: left;

        > div:first-child{
          font-weight: bold;
        }

        > div:last-child{
          @media only screen and (max-width: 767px) {
            text-align: right;
          }
        }
      }
    }
  }
}

stream-info-form {
   display: block;
   overflow: hidden;
}

.stream-info {
  @include max-tablet-width() {
    //overflow: hidden;
  }

  .info-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &.editing-old{
    box-shadow: 1px 1px 7px rgba(130, 130, 130, 0.5);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    background: rgb(231, 234, 237);
  }

  button{
    margin: 20px 0 5px 0;
  }

  .notd-stream-info-header{
    padding-bottom: 10px;
    color: #ad3a3a;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    margin: 0;

    @include max-phone-width() {
      flex: 1 0;
      padding: 0 15px 0 0;
    }
  }

  .btn-close{
    span{
      padding-left: 10px;
    }
  }

  .separate-description {
    font-size: 14px;
    display: none;
    padding: 0 0 10px;

    @include max-phone-width() {
      display: block;
    }
  }

  .notd-details {
    button {
      margin: 20px 5px;
    }
  }
}

.stream-copyright {
  color: $fifth-color-grey-don;
  font-size: 14px;
  text-align: center;
  padding: 20px 0 0;
}


::ng-deep ngb-modal-backdrop {
  background-color: #fff !important;
}

::ng-deep .following-modal {
  font-family: var(--font-family-georgia);

  .modal-dialog {
      .modal-content {
          position: relative;
          padding: 20px;

          @include box-shadow(rgb(0 0 0 / 15%) 0px 10px 20px 0px !important);

          @include max-phone-width() {
              padding: 10px;
          }
      }
  }

  &__close-btn {
      display: inline-flex;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;

      svg {
          height: 26px;
          width: 26px;

          &:hover {
              opacity: .6;
          }
      }
  }

  &__title {
      font-size: 1.4em;
      text-align: center;
      margin: 0;
      padding: 0;

      @include max-phone-width() {
          padding: 0 45px 0 0;
      }
  }

  &__content {
    padding: 25px 5px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        margin: 12px 0;
        cursor: pointer;

        input {
          margin: 0 10px 0 0;
        }

        span {
          flex: 1;
        }
      }
    }
  }

  &__footer {
    padding: 5px 0;
    text-align: center;

    button {
      font-size: 0.9em;
    }
  }
}

::ng-deep .blocked-users-modal {
  font-family: var(--font-family-georgia);

  .modal-dialog {
      .modal-content {
          position: relative;
          padding: 20px;

          @include box-shadow(rgb(0 0 0 / 15%) 0px 10px 20px 0px !important);
          @include max-phone-width() {
              padding: 10px;
          }
      }
  }

  &__close-btn {
      display: inline-flex;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;

      svg {
          height: 26px;
          width: 26px;

          &:hover {
              opacity: .6;
          }
      }
  }

  &__title {
      font-size: 1.4em;
      text-align: center;
      margin: 0;
      padding: 0;

      @include max-phone-width() {
          padding: 0 45px 0 0;
      }
  }

  &__content {
    &__text {
      margin: 20px 0 10px;
    }

    &__info {
      margin: 10px 0;
      display: flex;
      align-items: center;

      @include max-phone-width() {
          margin: 10px 5px;
      }

      svg {
          width: 26px;
          height: 26px;
          margin: 0 5px 0 0;
      }

      p {
          flex: 1;
          font-size: .8em;
          color: grey;
          margin: 0;
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      z-index: 1;

      button {
        margin: 0 2px;
        font-size: .8em;

        &:disabled {
          border: none;
        }
      }
    }

    &__list {
      list-style: none;
      margin: 20px 0 30px;
      padding: 0;

      &__counter {
        margin: 0 5px 0 0;
      }

      &__user {
        flex: 1;
        text-decoration: none;
        color: #39aea9;

        &:hover {
          color: black;
          text-decoration: none;
        }
      }

      &__actions {
        margin: 0 0 0 10px;

        button {
          font-size: .7em;
        }
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px 0;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &__message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 30px 10px 10px;
  
      svg {
        width: 250px;
        height: 250px;
      }
  
      p {
        padding: 20px 0;
      }
    }
  }
}

::ng-deep .stream-members-modal {
  font-family: var(--font-family-georgia);

  .modal-dialog {
      .modal-content {
          position: relative;
          padding: 20px;

          @include box-shadow(rgb(0 0 0 / 15%) 0px 10px 20px 0px !important);
          @include max-phone-width() {
              padding: 10px;
          }
      }
  }

  &__close-btn {
      display: inline-flex;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;

      svg {
          height: 26px;
          width: 26px;

          &:hover {
              opacity: .6;
          }
      }
  }

  &__title {
      font-size: 1.4em;
      text-align: center;
      margin: 0;
      padding: 0;

      @include max-phone-width() {
          padding: 0 45px 0 0;
      }
  }

  &__content {
    &__text {
      margin: 20px 0 10px;
    }

    &__invite {
      margin: 15px 20px 5px;
      border-bottom: 1px solid #8080804a;

      alert-message {
        .alert-wrapper {
          border: 1px solid #80808017;
        }
      }

      &__form {
        display: flex;
        justify-content: center;
        align-items: center;

        @include max-phone-width() {
          flex-direction: column;
        }

        &.form--invalid {
          padding: 0 0 15px;
        }

        label {
          margin: 0;
          padding: 0;

          @include max-phone-width() {
            margin: 0 0 10px;  
          }
        }

        &__input-wrapper {
          display: flex;
          flex-direction: column;
          position: relative;

          input {
            margin: 0 15px;
            font-family: var(--font-family-georgia);
          }

          &__error {
            font-size: 0.85em;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;

            @include max-phone-width() {
              position: relative;
              bottom: inherit;
              left: inherit;
              right: inherit;
              margin: 10px 0 0;
            }

            svg {
              width: 22px;
              height: 22px;
              margin: 0 8px 0 0;
            }
          }
        }

        button {
          font-size: .8em;
          padding: 10px;

          &:disabled {
            border: 1px solid #20202000;
          }

          &.button-spinner {
            padding: 10px 10px 10px 38px !important;

            &:after {
              left: 10px;
            }
          }
        }
      } 

      &__description {
        text-align: center;
        padding: 10px 0;
        margin: 0;
        font-size: .9em;
      }
    }

    &__info {
      margin: 10px 0;
      display: flex;
      align-items: center;

      @include max-phone-width() {
          margin: 10px 5px;
      }

      svg {
          width: 26px;
          height: 26px;
          margin: 0 5px 0 0;
      }

      p {
          flex: 1;
          font-size: .8em;
          color: grey;
          margin: 0;
      }
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
      z-index: 1;

      button {
        margin: 0 2px;
        font-size: .8em;

        &:disabled {
          border: none;
        }
      }
    }

    &__list {
      list-style: none;
      margin: 20px 0 30px;
      padding: 0;

      &__counter {
        margin: 0 5px 0 0;
      }

      &__user {
        flex: 1;
        text-decoration: none;
        color: #39aea9;

        &:hover {
          color: black;
          text-decoration: none;
        }
      }

      &__actions {
        margin: 0 0 0 10px;

        @include max-phone-width() {
          margin: 0 0 0 5px;
          display: flex;
          align-items: center;
        }

        &__status {
          text-transform: uppercase;
          font-size: 0.6em;
          margin: 0 15px;
          display: inline-block;
          text-align: center;
          width: 130px;
          padding: 5px 0;
          border: 1px solid black;

          @include radius(15px);

          @include max-phone-width() {
            margin: 0 10px;
            width: 90px;  
          }
        }

        button {
          font-size: .7em;
        }
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px 0;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &__message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 30px 10px 10px;
  
      svg {
        width: 250px;
        height: 250px;
      }
  
      p {
        padding: 20px 0;
      }
    }

    &__loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 100px auto;

      &__spinner {
        position: relative;
        background: #6c757d1a;
        @include radius(10px);
        width: 50px;
        height: 50px;

        &:after {
          position: absolute;
          content: '';
          border: 2px solid #444444;
          border-color: #44444478 #44444478 transparent transparent;
          width: 25px;
          height: 25px;
          top: 25%;
          left: 25%;
          @include animation(button-spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite);
          @include radius(50%);
          @include transform(translate(-25%, -25%));
        }
      }

      &__label {
        font-family: $cantarellFont;
        text-transform: uppercase;
        font-size: 0.7em;
        margin: 10px 0 0;
      }
    }

    &__info-message {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0 0;

      svg {
        width: 30px;
        height: 30px;
        margin: 0 8px 0 0;
      }

      p {
        margin: 0;
        padding: 0;
        font-size: .9em;
        color: grey;
      }
    }
  }
}

